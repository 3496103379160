<template>
  <div class="issue" v-if="mySelf">
    <v-card flat>
      <v-row>
        <v-col>
          <v-data-table
            :height="windowHeight"
            :headers="headers_issue"
            :items="Issues"
            sort-by="name1"
            class="elevation-1"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            @click:row="loadIssues"
            :item-class="row_classes"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Projects / Sub-Projects</v-toolbar-title>
                <!--<v-divider class="mx-4" inset vertical></v-divider>-->
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  left
                  fab
                  small
                  dark
                  class="mb-2"
                  @click="createItemIssue"
                >
                  <v-icon> add </v-icon>
                </v-btn>
                <v-dialog v-model="dialog_issue" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{ formTitleIssue }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <v-text-field
                          :disabled="mySelf.roles.includes('user')"
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIssue.name"
                          label="Name"
                        ></v-text-field>
                        <!-- <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIssue.color"
                          label="Color"
                        ></v-text-field> -->
                        <v-color-picker
                          v-model="selectedColor"
                          hide-inputs
                          width="500"
                        >
                        </v-color-picker>
                        <!-- <v-text-field
                          dense
                          flat
                          v-model="editedItemIssue.parent"
                          label="Parent"
                        ></v-text-field> -->
                        <v-select
                          :disabled="mySelf.roles.includes('user')"
                          :items="filteredIssues"
                          item-text="name"
                          item-value="_id"
                          label="Parent"
                          v-model="editedItemIssue.parent"
                        >
                          <!-- v-on:change="changeFilter" -->
                        </v-select>
                        <v-checkbox
                          :disabled="mySelf.roles.includes('user')"
                          dense
                          v-model="editedItemIssue.active"
                          label="Active"
                        ></v-checkbox>
                        <v-select
                          :disabled="mySelf.roles.includes('user')"
                          :items="Costcenters"
                          item-text="name"
                          item-value="_id"
                          label="Default Costcenter"
                          v-model="editedItemIssue.costcenter"
                        >
                        </v-select>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeIssue"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="saveIssue"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.parent="{ item }">
              {{ compIssueName(item) }}
            </template>
            <template v-slot:item.active="{ item }">
              <v-icon v-if="item.active" small>mdi-check</v-icon>
              <v-icon v-else small color="grey darken-2">mdi-cancel</v-icon>
            </template>
            <template v-slot:item.costcenter="{ item }">
              {{ compCostcenterName(item) }}
            </template>
            <template v-slot:item.user="{ item }">
              {{ compUserName(item) }}
            </template>
            <template v-slot:item.location="{ item }">
              {{ compLocation(item) }}
            </template>
            <template v-slot:item.color="{ item }">
              <v-icon small class="mr-2" :color="item.color">mdi-water</v-icon>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="changeUser(item)"
                >mdi-account-plus</v-icon
              >
              <v-icon small class="mr-2" @click="editItemIssue(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="deleteItemIssue(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="dialog_addUser" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1">User</span>
        </v-card-title>

        <v-card-text>
          <v-form
            v-model="valid"
            style="width: 100%; height: 100%"
            class="compact-form"
          >
            <!-- <v-textarea
              text="compUsersName(editedItemIssue)"
              label="User"
              disabled
            ></v-textarea> -->
            <div class="pa-2">
              {{ compUserName(editedItemIssue) }}
            </div>
            <v-data-table
              class="pa-2 pt-5"
              :headers="headers_user"
              :items="Users"
              sort-by="fullname"
              dense
              must-sort
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="addUser(item)"
                  >mdi-plus</v-icon
                >
                <v-icon small class="mr-2" @click="removeUser(item)"
                  >mdi-minus</v-icon
                >
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddUser">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveAddUser">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule, emailRule } from "../utils/rules";

export default {
  name: "issue",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      emailRule,

      dialog_delete: false,
      dialog_issue: false,
      dialog_addUser: false,

      headers_issue: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Color", value: "color" },
        { text: "Parent", value: "parent" },
        { text: "Active", value: "active" },
        { text: "Costcenter", value: "costcenter" },
        { text: "User", value: "user" },
        { text: "location", value: "location" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headers_user: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "fullname",
        },
        { text: "id", value: "_id" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedIndexIssue: -1,
      defaultItemIssue: {
        name: "",
        parent: "",
        user: [],
        active: true,
        costcenter: "",
        location: "",
      },
      editedItemIssue: {},

      snackbar: false,
      snackbar_text: "",
      companyIssues: [],

      filteredIssues: [],
      selectedColor: {},
    };
  },
  created() {
    this.editedItemIssue = JSON.parse(JSON.stringify(this.defaultItemIssue));
  },

  async mounted() {
    await this.getUsers();

    await this.getIssues({ location: this.$store.state.selectedLocation._id });
    //console.log(this.Issues);
    await this.getCostcenters({
      location: this.$store.state.selectedLocation._id,
      active: true,
    });

    await this.getLocation({});
  },
  computed: {
    ...mapState({
      status: ({ issue }) => issue.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Issues: ({ issue }) => issue.Issues,
      IssueState: ({ issue }) => issue,
      Users: ({ user }) => user.Users,
      Costcenters: ({ costcenter }) => costcenter.Costcenters,
      CostcenterState: ({ costcenter }) => costcenter,
      Locations: ({ location }) => location.Locations,
    }),

    windowHeight() {
      return String(window.innerHeight - 180) + "px";
    },

    formTitleIssue() {
      return this.editedIndexIssue === -1
        ? "New Project / Sub-Project"
        : "Edit Project / Sub-Project";
    },
    // compUsers() {
    //   // let userlist = "";
    //   // for (let user of this.editedItemIssue.user) {
    //   return   this.compUserName(this.editedItemIssue.user);
    //   // }
    //   //return userList;
    // }
  },
  methods: {
    ...mapActions("issue", [
      "getIssues",
      "createIssue",
      "updateIssue",
      "deleteIssue",
    ]),
    ...mapActions("costcenter", ["getCostcenters"]),
    ...mapActions("user", ["getUsers"]),
    ...mapActions("location", ["getLocation"]),

    row_classes(item) {
      if (item.active) {
        return "";
      } else {
        return "grey--text text--darken-2";
      }
    },

    changeFilter(id) {
      //console.log("FILTER: ", id)
      let _issues = _.filter(this.Issues, (obj) => {
        return obj._id != id;
      });
      _issues.push({ _id: null, name: "0. No Parent" });
      _issues = _.sortBy(_issues, "name");
      this.filteredIssues = _issues;
    },
    compIssueName(item) {
      if (item.parent == null) {
        return "";
      }
      try {
        return _.find(this.Issues, { _id: item.parent }).name;
      } catch (err) {
        return "";
      }
    },
    compCostcenterName(item) {
      if (item.costcenter == null) {
        return "";
      }
      try {
        return _.find(this.Costcenters, { _id: item.costcenter }).name;
      } catch (err) {
        return "";
      }
    },
    compUserName(item) {
      //console.log(item)
      if (item.user) {
        let arr = [];
        for (let user of item.user) {
          let u = undefined;
          if (
            this.mySelf.roles.includes("admin") ||
            this.mySelf.roles.includes("clientAdmin")
          ) {
            u = _.find(this.Users, { _id: user });
          } else {
            if (this.mySelf._id == user) {
              u = this.mySelf;
            }
            //console.log(u, user, this.mySelf)
          }
          if (u) {
            arr.push(u.fullname);
          }
        }
        return arr;
      } else {
        return "";
      }
    },
    compLocation(item) {
      if (item.location == null) {
        return "";
      }
      try {
        return _.find(this.Locations, { _id: item.location }).name;
      } catch (err) {
        return "";
      }
    },
    loadIssues(a) {
      //console.log(a);
      this.companyIssues = _.filter(this.Issues, { location: a._id });
    },

    async createItemIssue() {
      this.editedItemIssue = Object.assign({}, this.defaultItemIssue);
      this.editedItemIssue.location = this.$store.state.selectedLocation._id;
      this.editedIndexIssue = -1;
      this.changeFilter(null);
      this.dialog_issue = true;
    },

    async editItemIssue(item) {
      //console.log("editItem ",item);

      this.editedIndexIssue = this.Issues.indexOf(item);
      this.editedItemIssue = Object.assign({}, item);
      this.selectedColor = item.color;
      this.changeFilter(item._id);
      this.dialog_issue = true;
    },

    async deleteItemIssue(item) {
      //const index = this.Issues.indexOf(item);
      await this.deleteIssue(item._id);
      if (this.IssueState.statusDelete == "success") {
        //this.Issues.splice(index, 1);
        await this.getIssues({
          location: this.$store.state.selectedLocation._id,
        });
      } else {
        // this.snackbar_text = "Already deleted";
        // this.snackbar = true;
      }
    },

    closeIssue() {
      this.dialog_issue = false;
      this.$nextTick(() => {
        this.editedItemIssue = Object.assign({}, this.defaultItemIssue);
        this.editedIndexIssue = -1;
      });
    },

    async saveIssue() {
      //console.log("saveIssue");

      //console.log(this.editedItemIssue);
      //this.editedItemIssue.location = this.mySelf.location;
      if (this.editedIndexIssue > -1) {
        if (!this.editedItemIssue.user.includes(this.mySelf._id)) {
          this.editedItemIssue.user.push(this.mySelf._id);
        }
        //console.log("Update: ", this.editedItemIssue)

        if (typeof this.selectedColor == "object") {
          this.editedItemIssue.color = this.selectedColor.hexa;
        } else {
          this.editedItemIssue.color = this.selectedColor;
        }
        //console.log(this.editedItemIssue)
        await this.updateIssue(this.editedItemIssue);
        if (this.IssueState.statusUpdate == "success") {
          // this.editedItemIssue._id = this.IssueState.Issue._id;
          // this.editedItemIssue.updatedAt = this.$moment(
          //   this.IssueState.Issue.updatedAt
          // ).format("DD-MM-YYYY HH:mm");
          Object.assign(
            this.Issues[this.editedIndexIssue],
            this.editedItemIssue
          );
        } else {
          //this.snackbar_text = this.IssueState.Issue.message;
          this.snackbar_text = "Update failed";
          this.snackbar = true;
        }
      } else {
        if (!this.editedItemIssue.user.includes(this.mySelf._id)) {
          this.editedItemIssue.user.push(this.mySelf._id);
        }

        this.editedItemIssue.color = this.selectedColor.hexa;
        await this.createIssue(this.editedItemIssue);
        if (this.IssueState.statusCreate == "success") {
          // this.editedItemIssue._id = this.IssueState.Issue._id;
          // this.editedItemIssue.updatedAt = this.$moment(
          //   this.IssueState.Issue.updatedAt
          // ).format("DD-MM-YYYY HH:mm");
          this.Issues.push(this.editedItemIssue);
        } else {
          //this.snackbar_text = this.snackbar_text = this.IssueState.Issue.message;
          this.snackbar_text = "Create failed";
          this.snackbar = true;
        }
      }
      await this.getIssues({
        location: this.$store.state.selectedLocation._id,
      });
      this.closeIssue();
    },

    async changeUser(item) {
      //console.log(item)
      this.editedIndexIssue = this.Issues.indexOf(item);
      this.editedItemIssue = item;
      if (
        this.mySelf.roles.includes("admin") ||
        this.mySelf.roles.includes("clientAdmin")
      ) {
        //this.editedItemIssue.user = JSON.stringify(this.editedItemIssue.user);
        this.dialog_addUser = true;
      } else {
        item.user.push(this.mySelf._id);
        await this.saveIssue();
      }
    },

    closeAddUser() {
      try {
        //this.editedItemIssue.user = JSON.parse(this.editedItemIssue.user);
        this.dialog_addUser = false;
      } catch (err) {
        this.snackbar_text = err;
        this.snackbar = true;
      }
    },
    async saveAddUser(item) {
      try {
        //this.editedItemIssue.user = JSON.parse(this.editedItemIssue.user);
        await this.saveIssue();
        this.dialog_addUser = false;
      } catch (err) {
        this.snackbar_text = err;
        this.snackbar = true;
      }
    },
    addUser(item) {
      if (
        !_.find(this.editedItemIssue.user, (obj) => {
          return obj == item._id;
        })
      ) {
        this.editedItemIssue.user.push(item._id);
      }
    },
    removeUser(item) {
      if (
        _.find(this.editedItemIssue.user, (obj) => {
          return obj == item._id;
        })
      ) {
        const index = this.editedItemIssue.user.indexOf(item._id);
        this.editedItemIssue.user.splice(index, 1);
      }
    },
  },
  watch: {
    // editedIndexIssue(item) {
    //   console.log(item);
    // },
  },
};
</script>

<style scoped>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}

.colorBox {
  height: 20px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid black;
}
</style>