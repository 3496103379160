<template >
  <v-container class="fill-height pa-0">
    <v-row>
      <v-col class="caption">
        Chat Protocol of {{ issue.name }}
        <!--<v-btn @click="scrollBottom"> Scroll</v-btn>-->
      </v-col>
    </v-row>
    <v-row class="no-gutters pr-3" >
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="overflow-y-auto fill-height" :height="chat_height">
          <v-card flat class="d-flex flex-column fill-height">
            <div id="chat_box" class="flex-grow-1">
              <v-card-text class="pa-0 pr-2">
                <template>
                  <div
                    v-for="msg in new_chat_data"
                    :key="msg._id"
                    :class="
                      msg.user == user_id
                        ? 'd-flex flex-row-reverse'
                        : msg.issue == '0'
                        ? 'd-flex flex-row justify-center'
                        : 'd-flex flex-row'
                    "
                  >
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <div
                          :style="
                            msg.user == user_id
                              ? 'background-color: #00AA00FF;  border-radius: 15px; height: auto; white-space: normal; max-width: 90%;'
                              : msg.issue == '0'
                              ? 'height: auto; white-space: normal;'
                              : 'background-color: #0050FFFF;  border-radius: 15px; height: auto; white-space: normal; max-width: 90%;'
                          "
                          :align="msg.user == user_id ? 'end' : 'start'"
                          class="pa-0 px-3 ma-0 my-2 caption"
                        >
                          <div v-if="msg.user != user_id && msg.issue != '0'">
                            {{ msg.name }}<br />
                          </div>
                          <div v-else-if="msg.issue == '0'">
                            {{ msg.createdAt | moment("DD.MM.YY") }}
                          </div>
                          <v-hover
                            v-slot:default="{ hover }"
                            v-if="msg.issue != '0'"
                          >
                            <div class="white--text" v-on="on" dark>
                              {{ msg.msg }}
                              <sub class="pl-2">
                                {{ msg.createdAt | moment("HH:mm") }}
                              </sub>
                              <v-icon v-if="hover" small> expand_more </v-icon>
                            </div>
                          </v-hover>
                        </div>
                      </template>
                      <v-list
                        v-if="msg.user != user_id"
                        style="background-color: #50505080 !important"
                        class="pa-0 ma-0"
                      >
                        <v-list-item>
                          <v-icon small class="pl-3"> mdi-thumb-up </v-icon>
                          <v-icon small class="pl-3"> mdi-cancel </v-icon>
                          <v-icon small class="pl-3"> mdi-thumb-down </v-icon>
                        </v-list-item>
                      </v-list>
                      <v-list
                        v-else
                        style="background-color: #50505080 !important"
                        class="pa-0 ma-0"
                      >
                        <v-list-item>
                          <!--<v-icon small class="pl-3"> mdi-pencil </v-icon>-->
                          <v-icon small class="pl-3">
                            mdi-arrow-right-bold-box
                          </v-icon>
                          <v-icon
                            v-if="checkMessageCreatedAt(msg)"
                            small
                            class="pl-3"
                            @click="deleteChatMessage(msg)"
                          >
                            mdi-delete
                          </v-icon>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </template>
              </v-card-text>
            </div>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Child",
  props: [
    "chat_data",
    "issue",
    "socket",
    "chat_message",
    "user_id",
    "mySelf",
    "chat_height",
  ],
  data() {
    return {
      msg_height: 25,
      new_chat_data: [],
      //chat_height: 0,
    };
  },
  mounted() {},
  methods: {
    //...mapActions("chat", ["delMsg"]),
    scrollBottom() {
      //window.scrollTo(0, 100);
      let elmnt = document.getElementById("chat_box");
      elmnt.scrollIntoView(false);
    },

    calcChatName(msg) {
      if (this.user_id != msg.user) {
        return msg.name;
      } else {
        return "";
      }
    },

    async deleteChatMessage(msg) {
      console.log("delete chat message ", msg);
      this.socket.emit("chat_message_delete", msg);
      this.getIssueChat();
    },

    getIssueChat() {
      let msg = {
        issue: this.issue._id,
        // from: this.$moment().subtract(3,'days').toDate(),
        // to: this.$moment().toDate(),
        count: 25,
      };
      this.socket.emit("chat_new", msg);
    },

    checkMessageCreatedAt(msg) {
      const check =
        this.$moment(msg.createdAt) > this.$moment().subtract(5, "minutes");
      //console.log("checkMessageCreatedAt", msg, check);
      return check;
    },
  },

  computed: {
    /*chat_height(el) {
      console.log(this.$refs["chat_ref"].getBoundingClientRect().height);
      let height = window.innerHeight - 532;
      if (this.mySelf.roles.includes("admin")) {
        height = height - 108;
      }
      return height > 0 ? height : 0;
    },*/
    calc_msg_height() {
      if (this.msg_height == 15) {
        this.msg_height = 50;
      } else {
        this.msg_height = 15;
      }
      return this.msg_height;
    },
  },
  watch: {
    chat_data() {
      //console.log("New Chat Data: ", this.chat_data);
      //let last_msg = {};
      this.new_chat_data = [];
      let new_index = 0;
      for (let index in this.chat_data) {
        // if (index != 0) {
        //   console.log(
        //     "New Chat Data: ",
        //     this.$moment(this.chat_data[index].createdAt).format("DD.MM.YY") >
        //       this.$moment(this.chat_data[index - 1].createdAt).format(
        //         "DD.MM.YY"
        //       ),
        //     " ",
        //     this.$moment(this.chat_data[index].createdAt)
        //       .format("DD.MM.YY")
        //       .toDate(),
        //     " ",
        //     this.$moment(this.chat_data[index - 1].createdAt)
        //       .format("DD.MM.YY")
        //       .toDate()
        //   );
        // }
        if (index == 0) {
          this.new_chat_data[new_index] = {
            createdAt: this.chat_data[index].createdAt,
            _id: new_index,
            issue: "0",
          };
          new_index++;
        } else if (
          this.$moment(this.chat_data[index].createdAt).format("DD.MM.YY") >
          this.$moment(this.chat_data[index - 1].createdAt).format("DD.MM.YY")
        ) {
          this.new_chat_data[new_index] = {
            createdAt: this.chat_data[index].createdAt,
            _id: new_index,
            issue: "0",
          };
          new_index++;
        }
        this.new_chat_data[new_index] = this.chat_data[index];
        new_index++;
      }

      setTimeout(() => this.scrollBottom(), 50);
    },
    issue() {
      //console.log("Chat Issue changed ");

      this.getIssueChat();
    },
    chat_message() {
      let msg = {
        issue: this.issue._id,
        msg: this.chat_message,
      };
      this.socket.emit("chat_message", msg);

      setTimeout(() => this.scrollBottom(), 50);
    },
  },
};
</script>

<style lang='scss'>
.box {
  border: solid 1px red;
}
.greybox {
  border: solid 1px #5a5a5a;
}
.chatBoxOwn {
  border-radius: 25px;
  background: green;
}
.chatBoxForeign {
  border-radius: 25px;
  background: blue;
}
</style>