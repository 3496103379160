import api from "../../../api";

export default {
  async getIssues({ commit }, filter) {
    commit("REQUEST_ISSUES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_ISSUES",
        await api.getIssues(filter)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_ISSUES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteIssue({ commit }, id) {
    commit("REQUEST_DELETE_ISSUE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_ISSUE",
        await api.deleteIssue(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DELETE_ISSUE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateIssue({ commit }, item ) {
    commit("REQUEST_UPDATE_ISSUE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_ISSUE",
        await api.updateIssue(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_ISSUE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createIssue({ commit }, item ) {
    commit("REQUEST_CREATE_ISSUE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_ISSUE",
        await api.createIssue( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_ISSUE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
