<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex style="max-width: 500px">
        <v-card v-if="statusAccountActivation === 'success'" flat>
          <v-alert type="info" value="1">Account activated</v-alert>
          <v-card-text>You can now log in with your account.</v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="primary" :to="{ name: 'login' }">
              Log In
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else-if="statusAccountActivation === 'failure'" flat>
          <v-alert type="warning" value="1" class="black--text"
            >Account activation message</v-alert
          >
          <v-card-text
            >User does not exists or is already activated.</v-card-text
          >
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="primary" :to="{ name: 'login' }">
              Log In
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      statusAccountActivation: ({ user }) => user.statusAccountActivation,
      statusAuth: ({ auth }) => auth.status,
      loading: function () {
        return this.statusAccountActivation === "fetching";
      },
    }),
  },

  mounted() {
    if (this.statusAuth === "success") {
      this.logout();
    } else {
      this.activateAccount({ token: this.$route.params.token });
    }
  },

  watch: {
    statusAccountActivation(val) {
      if (val === "success") {
        // setTimeout(() => {
        //   this.$router.push("/login");
        // }, 3000);
      }
    },
  },

  methods: {
    ...mapActions("user", ["activateAccount"]),
    ...mapActions("auth", ["logout"]),
  },
};
</script>
