const REQUEST_ISSUES = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_ISSUES = (state, { body }) => {
  state.Issues = body;
  state.status = "success";
};

const FAILURE_REQUEST_ISSUES = state => {
  state.status = "failure";
};

const REQUEST_DELETE_ISSUE = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_ISSUE = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_ISSUE = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_ISSUE = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_ISSUE = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_ISSUE = state => {
  state.statusUpdate = "failure";
};


const REQUEST_CREATE_ISSUE = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_ISSUE = (state, { body }) => {
  state.CreatedIssue = body
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_ISSUE = state => {
  
  state.statusCreate = "failure";
};


export default {
  REQUEST_ISSUES,
  SUCCESS_REQUEST_ISSUES,
  FAILURE_REQUEST_ISSUES,
  REQUEST_CREATE_ISSUE,
  SUCCESS_REQUEST_CREATE_ISSUE,
  FAILURE_REQUEST_CREATE_ISSUE,
  REQUEST_DELETE_ISSUE,
  SUCCESS_REQUEST_DELETE_ISSUE,
  FAILURE_REQUEST_DELETE_ISSUE,
  REQUEST_UPDATE_ISSUE,
  SUCCESS_REQUEST_UPDATE_ISSUE,
  FAILURE_REQUEST_UPDATE_ISSUE,

};
