import api from "../../../api";

export default {
  async getCostcenters({ commit }, filter) {
    commit("REQUEST_COSTCENTERS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_COSTCENTERS",
        await api.getCostcenters(filter)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_COSTCENTERS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteCostcenter({ commit }, id) {
    commit("REQUEST_DELETE_COSTCENTER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_COSTCENTER",
        await api.deleteCostcenter(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DELETE_COSTCENTER");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateCostcenter({ commit }, item ) {
    commit("REQUEST_UPDATE_COSTCENTER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_COSTCENTER",
        await api.updateCostcenter(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_COSTCENTER");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createCostcenter({ commit }, item ) {
    commit("REQUEST_CREATE_COSTCENTER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_COSTCENTER",
        await api.createCostcenter( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_COSTCENTER");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
