<template>
  <div @click="closeRadMenu" :style="cssVars">
    <loading
      :active.sync="loading_data"
      :is-full-page="fullPage"
      :lock-scroll="true"
      color="orange"
      :height="200"
      :width="200"
      :opacity="0.15"
      loader="bars"
    >
    </loading>
    <v-card flat class="calendarSection" >
      <v-row no-gutters>
        <v-col cols="7" class="pr-4">
          <v-row>
            <v-col
              class="pt-0"
              @mousedown="mouseEvent(1)"
              @mouseup="mouseEvent(0)"
            >
              <FullCalendar ref="fullCalendarRef" :options="calendarOptions" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-alert v-if="showTrackingAlert" prominent type="error">
            <v-row align="center">
              <v-col class="grow"> Time Tracking ist aktiv! </v-col>
              <v-col class="shrink">
                <v-btn v-on:click="alertTrackingOk">Stoppen</v-btn>
              </v-col>
              <v-col class="shrink">
                <v-btn v-on:click="alertTrackingCancel">Abbrechen</v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-sheet height="24" />
          <!-- <v-toolbar flat > -->
          <v-row>
            <v-col
              cols="12"
              align="center"
              class="pa-0 ma-0 pr-4"
              v-touch="{
                left: () => checkCalendarNext(),
                right: () => checkCalendarPrev(),
              }"
              v-if="!teamView"
            >
              <v-btn
                width="10px"
                x-small
                outlined
                color="grey darken-2"
                @click="checkCalendarPrev"
              >
                <v-icon dense> mdi-chevron-left </v-icon>
              </v-btn>
              <span class="caption mx-2">SWIPE ME</span>
              <v-btn
                width="10px"
                x-small
                outlined
                color="grey darken-2"
                @click="checkCalendarNext"
              >
                <v-icon dense> mdi-chevron-right </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              align="center"
              class="pa-0 ma-0 pr-4"
              v-touch="{
                left: () => checkTeamViewNext(),
                right: () => checkTeamViewPrev(),
              }"
              v-else
            >
              <v-btn
                width="10px"
                x-small
                outlined
                color="grey darken-2"
                @click="checkTeamViewPrev"
              >
                <v-icon dense> mdi-chevron-left </v-icon>
              </v-btn>
              <span class="caption mx-2">SWIPE ME</span>
              <v-btn
                width="10px"
                x-small
                outlined
                color="grey darken-2"
                @click="checkTeamViewNext"
              >
                <v-icon dense> mdi-chevron-right </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="!teamView">
            <v-col cols="12" class="pa-0 mt-1 pr-4 white--text" align="center">
              {{ calendarDateStart }} {{ calendarDateEnd }}
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" class="pa-0 mt-1 pr-4 white--text" align="center">
              {{ teamViewDateStart | moment("ddd DD MMM YYYY") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pa-0 pb-0 mt-2 mb-4 pr-4" align="center">
              <v-btn
                v-if="compView == 'Week' && !isToday"
                x-small
                outlined
                class="pa-1"
                color="grey darken-2"
                @click="setToday"
              >
                Now
              </v-btn>
              <v-btn
                x-small
                outlined
                class="ml-2 pa-1"
                color="grey darken-2"
                @click="toggleView"
                v-if="!teamView"
              >
                {{ compView }}
              </v-btn>
              <v-btn
                x-small
                outlined
                class="ml-2 pa-1"
                color="grey darken-2"
                @click="changeTeamView"
                v-if="!isWeekView || teamView"
              >
                {{ compViewTeam }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="Locations.length > 1" class="pa-0 pt-4 pr-4">
            <v-select
              dense
              :items="Locations"
              item-text="name"
              item-value="_id"
              label="Client"
              v-model="selectedLocation"
              v-on:change="changeUser"
              return-object
            >
            </v-select>
          </v-row>
          <v-row v-if="isAdmin" class="pa-0 pt-4 pr-4">
            <v-select
              dense
              :items="Users"
              item-text="fullname"
              item-value="_id"
              label="User"
              v-model="selectedUser"
              v-on:change="changeUser"
            >
            </v-select>
          </v-row>
          <v-row
            v-if="ReadUser && ReadUser.all"
            class="pa-0 pt-0 mr-1 caption greybox"
          >
            OT Total:
            <div
              v-if="ReadUser.all.overtime >= 0"
              class="font-weight-bold green--text px-1"
            >
              {{ ReadUser.all.overtime }}
            </div>
            <div v-else class="font-weight-bold red--text px-1">
              {{ ReadUser.all.overtime }}
            </div>
            | act. Week:
            <div
              v-if="ReadUser.week.overtime >= 0"
              class="font-weight-bold green--text px-1"
            >
              {{ ReadUser.week.overtime }}
            </div>
            <div v-else class="font-weight-bold red--text px-1">
              {{ ReadUser.week.overtime }}
            </div>
            | today:
            <div
              v-if="ReadUser.day.overtime >= 0"
              class="font-weight-bold green--text px-1"
            >
              {{ ReadUser.day.overtime }}
            </div>
            <div v-else class="font-weight-bold red--text px-1">
              {{ ReadUser.day.overtime }}
            </div>
          </v-row>
          <v-row
            class="pa-0 pt-2"
            justify="space-between"
            ref="menuRef"
            height="100px"
          >
            <v-col class="pa-1 mr-1 greybox subtitle-2">
              {{ compIssueLabel }}
            </v-col>
            <v-col class="pa-0 pl-0" ref="radRef">
              <div >
                <v-btn
                  :disabled="m1.visible"
                  class="caption white--text"
                  color="blue darken-1"
                  fab
                  large
                  @click="showMenu(m1)"
                  >Projects
                </v-btn>
                <!-- v-if="menuConfig.btns.length > 1" -->
                <radial-menu
                  id="radId"
                  v-if="m1.visible && menuConfig.btns.length > 1"
                  style="z-index: 100"
                  :conf="m1.conf"
                  :pos="m1.pos"
                  :visible="m1.visible"
                  @reduceMenu="redMenu"
                />
                <!-- :visible="m1.visible" -->
              </div>
              <!-- <div v-else>
                <v-btn
                  :disabled="!m1.visible"
                  class="caption white--text"
                  color="blue darken-1"
                  fab
                  large
                  >Projects
                </v-btn>
              </div> -->
            </v-col>
          </v-row>
          <br />

          <v-row class="pt-2 mr-1">
            <v-col cols="12" class="pa-0 pt-3">
              <v-select
                dense
                :items="Costcenters"
                item-text="name"
                item-value="_id"
                label="Costcenter"
                v-model="selectedCostcenter"
              >
              </v-select>
            </v-col>
            <v-col cols="12" class="greybox pa-0 pt-2">
              <!-- <v-textarea             
                  class="pt-0 pr-3"
                  no-resize
                  name="Description"
                  label="Description"
                  v-model="description"
                  rows="2"               
                ></v-textarea> -->

              <v-text-field
                id="text_input_field"
                clearable
                placeholder=""
                ref="description"
                dense
                flat
                v-model="description"
                label="Description"
                @keydown.exact.enter="commitEvent(last_event, true)"
                @keydown.shift.enter="sendChatMessage"
              ></v-text-field>
            </v-col>

            <v-col cols="4" align="left" class="pa-0 pr-2 pt-2">
              <v-btn
                small
                color="blue darken-1 white--text"
                width="100%"
                @click="commitEvent(last_event, true)"
                ><v-icon>mdi-update</v-icon></v-btn
              >
            </v-col>
            <v-col cols="4" align="right" class="pa-0 pl-1 pr-1 pt-2">
              <v-btn
                small
                color="orange darken-3 white--text"
                width="100%"
                @click="cancel(last_event)"
                ><v-icon>mdi-recycle</v-icon></v-btn
              >
            </v-col>
            <v-col cols="4" align="right" class="pa-0 pl-2 pr-2 pt-2">
              <v-btn
                small
                color="yellow darken-2 white--text"
                width="100%"
                @click="sendChatMessage"
                ><v-icon>mdi-send</v-icon></v-btn
              >
            </v-col>
            <!-- <div ref="Previous" class="box" >PREV</div>
             <div ref="Next" class="box" >NEXT</div> -->
          </v-row>
          <v-row class="pt-5 mr-1">
            <v-col
              cols="12"
              align="left"
              class="pa-0 pr-2"
              v-if="!isTimeTracking"
            >
              <v-btn
                v-if="!isNowEvent && isToday && calendarView == 'timeGridDay'"
                small
                color="green darken-2 white--text"
                width="100%"
                @click="startTimeTracking"
                >Start Tracking</v-btn
              >

              <v-btn
                v-else-if="!isToday || calendarView != 'timeGridDay'"
                disabled
                small
                color="green darken-2 white--text"
                width="100%"
                >No dayview</v-btn
              >
              <v-btn
                v-else-if="isNowEvent"
                disabled
                small
                color="green darken-2 white--text"
                width="100%"
                >Blocked</v-btn
              >
            </v-col>
            <v-col cols="12" align="right" class="pa-0" v-else>
              <v-btn
                small
                color="red darken-2 white--text"
                width="100%"
                @click="stopTimeTracking"
              >
                <v-progress-circular
                  indeterminate
                  class="mr-2"
                  :size="15"
                  :width="2"
                  color="secondary"
                >
                </v-progress-circular>
                Stop Tracking</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="pt-5 mr-1">
            <v-col class="pa-0 pl-2 greybox" ref="chat_ref">
              <Chat
                style="max-height: 50px, overflow-y: scroll"
                :chat_data="chat_data"
                :chat_message="chat_message"
                :issue="selectedIssue"
                :socket="socket"
                :user_id="selectedUser"
                :mySelf="mySelf"
                :chat_height="chat_height"
              />
            </v-col>
          </v-row>

          <!-- <div class="font-weight-bold">Willkommen {{ mySelf.nickname }}</div>
            <p>Guthaben: {{ getTokenBalance }} {{ TokenName }}s</p> -->
          <!-- </div> -->
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import Locale from "@fullcalendar/core/locales/de";

import Icons from "../utils/icons.json";
import TextToSVG from "text-to-svg";

//import RadialMenu from 'custom-radial-menu/radial-menu-component.common.js'
import RadialMenu from "../utils/toms-radial-menu";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

//import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from "socket.io-client";
import { WS_ROOT } from "../config";

import Chat from "../components/Chat";

export default {
  name: "Home",
  props: {
    //machine: {},
    //isActiveContract: 0,
    /*events: {
      type: Array,
      default: [],
    },*/
  },
  components: {
    FullCalendar,
    RadialMenu,
    Loading,
    Chat,
    //VueCalendarResources
    //PayPalDialog
  },
  data() {
    return {
      loading_data: true,
      fullPage: true,
      snackbar: false,
      snackbar_text: "",
      updateTimeInterval: undefined,
      calendarOptions: {},
      description: "",
      issue_layers: [],
      selectedIssueIdx: 0,
      selectedIssue: {},
      changeIssueLayer: false,
      lastIssue: null,
      timeOffset: 0,
      windowWidth: 0,
      windowHeight: 0,
      event_color_selected: "blue",
      event_border_color_selected: "white",
      event_border_color_unselected: "black",

      calendar: {},
      event: {},
      select: {},
      slotDuration: 15,
      //select_selected: false,
      //event_selected: false,
      calendarEvents: [],
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        bootstrapPlugin,
      ],
      calendarHeader2: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
      },
      calendarHeader: {
        left: "",
        center: "",
        right: "",
      },
      calendarView: "timeGridDay",
      calendarApi: {},
      calendarDateStart: "",
      calendarDateEnd: "",
      teamViewDateStart: "",
      locations: ["Cal1"],
      actual_location: 0,
      fullCalendarName: ["fullCalendar"],
      last_event: {
        name: "",
      },
      minTime: { hours: 0 },
      maxTime: { hours: 24 },
      maxSelectHours: 24,
      Locale: Locale,
      order_number: "",
      delivery_company: "",

      headers_events: [
        {
          text: "Order Number",
          align: "start",
          sortable: true,
          value: "order_number",
        },
        { text: "Status", value: "status" },
        { text: "Company", value: "company" },
        { text: "From", value: "starttime" },
        { text: "To", value: "endtime" },
        { text: "Commit Arrival", value: "actions", sortable: false },
      ],

      selectedCostcenter: "",
      isWeekView: false,

      selectedUser: undefined,
      selectedLocation: undefined,
      myIssues: [],

      menuConfig: {
        angle: 265,
        aperture: 200,
        radius: 40,
        height: 0,
        btns: [
          {
            aperture: 0.075,
            height: 50,
            title: "Close",
            icon: Icons.x,
            action: async () => {
              this.m1.visible = false;
              //this.generateMenuButtons();
            },
            backgroundcolor: "white",
            textcolor: "black",
          },
        ],
      },
      m1: {
        conf: {},
        pos: { x: 32, y: -32 },
        visible: false,
      },

      calendarHeight: 50,

      isTimeTracking: false,
      trackEvent: {},
      showTrackingAlert: false,

      swipeDirection: 0,
      isToday: false,

      lastCreateUpdateTime: new Date(),
      delayMsForCreateUpdate: 200,

      mouseBtnStatus: 0,
      dragging: false,

      //connection: null
      socket: null,
      ws_refresh_enabled: false,
      start_time: null,
      chat_data: [],
      chat_message: {},
      teamView: false,
      chat_height: 0,

      last_refresh_time: new Date(),

      triggerShowMenu: false,
    };
  },
  created: function () {
    window.addEventListener("keydown", this.handleKeyPressed);
    this.m1.conf = this.menuConfig;
    window.addEventListener("resize", this.handleResize);
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    this.timeOffset = new Date().getTimezoneOffset();
    //this.calculateRentPrice = this.calculateRentPriceTemplate;
    //window.addEventListener('keypress', this.rent());
    //this.updateTime();
    this.selectedUser = this.mySelf._id;

    this.calendarOptions = {
      plugins: this.calendarPlugins,
      initialView: this.calendarView,
      //dateClick: this.handleDateClick,
      events: [],
      headerToolbar: this.calendarHeader,
      weekends: true,
      selectable: true,
      nowIndicator: true,
      slotDuration: { minutes: this.slotDuration },
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
      },
      slotLabelInterval: { hours: 1 },
      locale: this.Locale,
      unselectAuto: true,
      selectOverlap: false,

      slotMinTime: this.minTime,
      slotMaxTime: this.maxTime,
      allDaySlot: false,
      dayHeaderFormat: { weekday: "short" },
      firstDay: 1,
      height: window.innerHeight - 100,

      dateClick: this.dateClick,
      eventClick: this.eventClick,
      select: this.dateSelect,
      eventDrop: this.eventDrop,
      eventResizeStart: this.eventResizeStart,
      eventResize: this.eventResize,
      eventDragStart: this.eventDragStart,
      eventDragStop: this.eventDragStop,

      //draggable: true,
      //themeSystem: "default",
      themeSystem: "standard",
      displayEventTime: true,
      longPressDelay: 200,
      editable: true,
      //eventOverlap: this.overlapAlarm,
      eventOverlap: false,
      eventTextColor: "white",
      eventBorderColor: this.event_border_color_unselected,
      //eventRender="customEventRender"
      eventContent: this.customEventRender,
      scrollTimeReset: false,
    };
    this.calendarOptions.height = window.innerHeight - this.calendarHeight;
  },
  destroyed() {
    //window.removeEventListener('keypress', this.rent());
    this.socket.close();
    clearInterval(this.updateTimeInterval);
    window.removeEventListener("keydown", this.handleKeyPressed);
  },
  updated() {
    //
  },
  async mounted() {
    //this.start_time = new Date();
    try {
      this.establishWebSockets();

      //console.log("WS Init finished");

      this.calendar = this.$refs.fullCalendarRef;
      this.calendarApi = this.calendar.getApi();
      this.calendarApi.updateSize();

      this.$moment.locale("en");

      //console.log(this.start_time);

      this.getLocation({}).then(() => {
        console.log("got Location ", this.Locations);
        if (this.Locations.length == 0) {
          this.logout();
        }
        if (this.$store.state.selectedLocation) {
          this.selectedLocation = this.$store.state.selectedLocation;
        } else {
          this.selectedLocation = this.Locations[0];
          this.$store.state.selectedLocation = this.Locations[0];
        }
        this.getCostcenters({
          location: this.$store.state.selectedLocation._id,
          active: true,
        });
        this.readUser({
          id: this.selectedUser,
          location_id: this.selectedLocation._id,
        });
        this.getIssues({
          user: this.selectedUser,
          location: this.$store.state.selectedLocation._id,
          active: true,
        }).then(() => {
          console.log("got Issues");
          this.myIssues = this.Issues.filter((obj) => {
            return obj.user.includes(this.selectedUser) && obj.active;
          });
          this.loadIssues(null);
          this.generateMenuButtons();
          this.selectedIssueIdx = 0;
          if (this.issue_layers.length > 0) {
            this.selectedIssue = this.issue_layers[0];
            this.selectedCostcenter = this.selectedIssue.costcenter;
          }

          this.setToday();
          this.calcCalendarDate();

          this.updateTimeInterval = this.refresh(200);
        });
      });

      //if (this.isAdmin) {
      this.getUsers({});
      //}

      //this.$moment.locale("cs");
      //this.$moment.locale("en");
      //this.calendarView = "timeGridDay";
      this.teamViewDateStart = this.$moment(
        this.calendarApi.getDate()
      ).toDate();
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    ...mapActions("auth", ["getMySelf", "logout"]),
    ...mapActions("event", [
      "getEvents",
      "deleteEvent",
      "updateEvent",
      "createEvent",
    ]),
    ...mapActions("issue", ["getIssues"]),
    ...mapActions("costcenter", ["getCostcenters"]),
    ...mapActions("user", ["getUsers", "readUser"]),
    ...mapActions("location", ["getLocation"]),

    checkTeamViewNext() {
      this.swipeDirection = 1;
      if (this.isTimeTracking) {
        this.showTrackingAlert = true;
      } else {
        this.teamViewSwitch();
      }
    },

    checkTeamViewPrev() {
      this.swipeDirection = 2;
      if (this.isTimeTracking) {
        this.showTrackingAlert = true;
      } else {
        this.teamViewSwitch();
      }
    },

    async teamViewSwitch() {
      if (this.swipeDirection == 1) {
        this.teamViewDateStart = this.$moment(this.teamViewDateStart)
          .add(1, "day")
          .toDate();

        await this.loadTeamEvents();
        this.refreshTeamView();
      } else if (this.swipeDirection == 2) {
        this.teamViewDateStart = this.$moment(this.teamViewDateStart)
          .subtract(1, "day")
          .toDate();

        await this.loadTeamEvents();
        this.refreshTeamView();
      }
      //this.$store.state.user.selectedDate = this.calendarApi.getDate();
      //this.calcCalendarDate();
    },

    generateTeamHtml(element, i, maxTextLength, el_size) {
      //console.log("HTML ", el_size);
      let text_size = el_size / maxTextLength / 10;
      if (text_size > 1) {
        text_size = 1;
      }
      element.setAttribute(
        "style",
        "font-size:" + text_size + "em !important;"
      );
      element.innerHTML = this.Users[i].displayname;
    },

    refreshTeamView() {
      if (this.teamView) {
        let elements = document.getElementsByClassName(
          "fc-col-header-cell-cushion"
        );
        let outer_elements = document.getElementsByClassName(
          "fc-scrollgrid-sync-inner"
        );
        let el_size = outer_elements[1].getBoundingClientRect().width;
        //console.log("HTML refreshTeamView ", el_size);

        let maxTextLength = 0;
        for (let i = 0; i < elements.length; i++) {
          if (this.Users[i]) {
            if (this.Users[i].displayname.length > maxTextLength) {
              maxTextLength = this.Users[i].displayname.length;
            }
          }
        }
        for (let i = 0; i < elements.length; i++) {
          if (this.Users[i]) {
            this.generateTeamHtml(elements[i], i, maxTextLength, el_size);
          } else {
            elements[i].innerHTML = "";
            elements[i].removeAttribute("style");
          }
          //console.log(elements[i], i);
        }
      }
    },
    async changeTeamView() {
      this.teamViewDateStart = this.$moment(
        this.calendarApi.getDate()
      ).toDate();
      // format(
      //   "DD MMM YYYY"
      // );
      this.teamView = !this.teamView;
      let elements = document.getElementsByClassName(
        "fc-col-header-cell-cushion"
      );

      if (this.teamView) {
        this.calendarView = "timeGridWeek";
        this.calendarOptions.selectable = false;
        this.calendarOptions.editable = false;
        this.isWeekView = true;

        await this.loadTeamEvents();

        this.calendarApi.changeView(this.calendarView);
        this.calcCalendarDate();

        //console.log(this.Users)
        let maxTextLength = 0;
        for (let i = 0; i < elements.length; i++) {
          if (this.Users[i]) {
            if (this.Users[i].displayname.length > maxTextLength) {
              maxTextLength = this.Users[i].displayname.length;
            }
          }
        }
        let outer_elements = document.getElementsByClassName(
          "fc-scrollgrid-sync-inner"
        );
        let el_size = outer_elements[0].getBoundingClientRect().width;
        //console.log("HTML changeTeamView ", el_size);

        for (let i = 0; i < elements.length; i++) {
          if (this.Users[i]) {
            this.generateTeamHtml(elements[i], i, maxTextLength, el_size);
          } else {
            elements[i].innerHTML = "";
            elements[i].removeAttribute("style");
          }
          //console.log(elements[i], i);
        }
      } else {
        for (let i = 0; i < elements.length; i++) {
          elements[i].innerHTML = "";
          elements[i].removeAttribute("style");
          //console.log(elements[i], i);
        }
        this.calendarView = "timeGridDay";
        this.isWeekView = false;
        await this.loadEvents(this.isWeekView);

        this.calendarOptions.selectable = true;
        this.calendarOptions.editable = true;
        this.calendarApi.changeView(this.calendarView);
        this.calcCalendarDate();
        this.calendarApi.render();
      }
    },

    keyPressed(event) {
      console.log(event);
    },
    sendChatMessage() {
      if (this.description.length > 0) {
        this.chat_message = this.description;
        this.description = "";
      }
    },

    mouseEvent(ev) {
      this.mouseBtnStatus = ev;
    },
    establishWebSockets() {
      this.socket = io(WS_ROOT, {
        //withCredentials: true,
        //transports: ["polling", "websocket"],
        transports: ["websocket"],
        query: {
          token: this.mySelf.activation_token,
        },
        // extraHeaders: {
        //   Cookie: "ABCDE"
        // }
        // extraHeaders: {
        //   "custom_header": JSON.stringify({token: this.mySelf.activation_token})
        // }
      });

      this.socket.on("connect", () => {
        console.log("Connect Ok, Send Data ", this.socket.id);
        // either with send()
        //this.socket.send("Hello SEND!");

        // or with emit() and custom event names
        // this.socket.emit(
        //   "chat",
        //   "!!!!!!!!!! Hello Sal",
        //   { mr: "john" },
        //   Uint8Array.from([1, 2, 3, 4])
        // );
      });

      // handle the event sent with socket.send()
      // this.socket.on("message", (data) => {
      //   try {
      //     console.log("WS Message: ", data);
      //   } catch (err) {
      //     console.log(err);
      //   }
      // });

      // handle the event sent with socket.emit()
      // this.socket.on(this.mySelf._id, (elem1, elem2, elem3) => {
      //   console.log(elem1, elem2, elem3);
      // });
      this.socket.on(String(this.mySelf._id), (data) => {
        try {
          //console.log("WS Data: ", data);
          this.ws_refresh_enabled = true;
        } catch (err) {
          console.log(err);
        }
      });

      this.socket.on("chat_new", (data) => {
        //console.log("chat_new: ", data);
        this.chat_data = data;
      });

      this.socket.on("chat_message", (data) => {
        //console.log("chat_message: ", data);

        this.chat_data.push(data);
        //this.chat_message = data.msg;
      });

      this.socket.on("error", (err) => {
        console.log("Error: ", err);
      });

      this.socket.on("connect_failed", () => {
        console.log("Sorry, there seems to be an issue with the connection! ");
      });

      // this.socket.on("connecting", () => {
      //   console.log("Connecting WS ");
      // });
    },
    async refresh(_interval) {
      return setInterval(async () => {
        if (
          this.$moment(this.last_refresh_time).date() != this.$moment().date()
        ) {
          //console.log("refresh 0: ", this.$moment().date());
          this.setToday();
        }
        this.last_refresh_time = new Date();

        if (this.ws_refresh_enabled) {
          //console.log("refresh 1 ", this.dragging, this.mouseBtnStatus);

          if (!this.dragging && !this.mouseBtnStatus) {
            //console.log("refresh 2");
            await this.loadEvents(this.isWeekView);
            if (this.Event.status == "success") {
              if (typeof this.last_event !== "undefined") {
                if (this.last_event._id) {
                  let ev = _.find(this.calendarOptions.events, {
                    _id: this.last_event._id,
                  });
                  if (ev) {
                    ev.borderColor = this.event_border_color_selected;
                    ev.backgroundColor = "#AAAAAA00";
                    ev.backgroundColor = this.last_event.backgroundColor;
                    this.last_event = ev;
                  }
                  //this.recolorEvent();
                }
              }
            } else {
              this.logout();
            }
            this.ws_refresh_enabled = false;
          }

          if (this.isTimeTracking) {
            let track = _.find(this.calendarOptions.events, {
              _id: this.trackEvent.id,
            });
            let end = this.roundTime(this.$moment());
            let eventsBetween = _.filter(this.calendarOptions.events, (obj) => {
              return (
                this.$moment(obj.start) > this.$moment(track.start) &&
                this.$moment(obj.start) < end
              );
            });
            //console.log(track.start, end.toDate(), track.start < end.toDate());
            if (eventsBetween.length > 0) {
              this.stopTimeTracking();
            } else {
              if (this.$moment(track.end) < end) {
                track.end = end.toDate();
                await this.commitEvent(track);
                //console.log("COMMITED ", track);
              }
            }
          }
          //console.log(this.last_event);
        }
      }, _interval);
    },

    customEventRender(obj) {
      let durationMin = this.$moment
        .duration(obj.event.end - obj.event.start)
        .minutes();
      let durationHours = this.$moment
        .duration(obj.event.end - obj.event.start)
        .hours();
      //console.log(obj.event, obj.el, obj.el.getElementsByClassName('fc-time')[0]);
      //obj.el.getElementsByClassName('fc-time')[0].append(document.createTextNode(" (" + durationHours + "h " + durationMin + "min)") );
      let html = "";
      //console.log(this.$moment.duration(obj.event.end - obj.event.start)._milliseconds, this.slotDuration * 60 * 1000)
      if (
        this.$moment.duration(obj.event.end - obj.event.start)._milliseconds >
        this.slotDuration * 60 * 1000
      ) {
        html =
          "<div><b>" +
          obj.timeText +
          " (" +
          durationHours +
          "h " +
          durationMin +
          "min)</b></div><div style='font-size: 1.0em; margin-top: -1px; line-height: 125%;'>" +
          obj.event.title +
          "</div>";
      } else {
        html =
          "<div><b>" +
          obj.timeText +
          " (" +
          durationHours +
          "h " +
          durationMin +
          "min)</b> " +
          obj.event.title +
          "</div>";
      }
      //if (this.slotDuration)
      return {
        html: html,
      };
    },

    toSVG(text) {
      return new Promise((resolve, reject) => {
        try {
          TextToSVG.load("./SourceSansPro-Regular.otf", (error, textToSVG) => {
            // console.log("TexttoSVG: ", textToSVG)
            const svg = textToSVG.getSVG(text);
            resolve(svg);
          });
        } catch (err) {
          reject(err);
        }
      });
    },
    showMenu(instance) {
      console.log("SHOW MENU")
      this.triggerShowMenu = true;
      //this.generateMenuButtons();
      instance.visible = false;
      // setTimeout(() => {
      //   //instance.pos = {x: e.pageX, y: e.pageY};
      //   instance.visible = true;
      // }, 200);
      this.$nextTick(() => {
          instance.visible = true;
        });
      return false;
    },

    customFilter(object, _id) {
      if (object) {
        if (object.hasOwnProperty("_id") && object["_id"] == _id) return object;

        for (let i = 0; i < Object.keys(object).length; i++) {
          if (typeof object[Object.keys(object)[i]] == "object") {
            let o = this.customFilter(object[Object.keys(object)[i]], _id);
            if (o != null) return o;
          }
        }
      }
      return null;
    },

    async closeRadMenu() {
      //
      if (!this.triggerShowMenu) {
        this.m1.visible = false;
      } else {
        this.triggerShowMenu = false;
        this.generateMenuButtons();
      }
      //console.log("closeRadMenu ", window.event.clientX, window.event.clientY, )
    },

    async redMenu(obj) {
      //console.log("Reduce Menu", obj);
      //if (obj.title != "Close") {
      if (obj.id != 0) {
        this.generateMenuButtons(obj);
        this.showMenu(this.m1);
      }
    },

    async generateMenuButtons(root) {
      try {
        //ToDo: Accelerate Function - Very Slow - Maybe pregenerate svg
        //console.log("start Gen Menu");
        //console.log(new Date() - this.start_time);
        this.start_time = new Date();
        //let obj = { height: 50, title:"Pen", icon: Icons.pen,  action: ()=>{ console.log('action'); this.m1.visible=false; this.generateMenuButtons();}}
        let user = _.find(this.Users, { _id: this.selectedUser });
        //console.log("generateMenuButtons user ", user);
        this.menuConfig.btns = [
          {
            aperture: 0.075,
            height: 50,
            title: "Close",
            icon: Icons.x,
            action: () => {
              //this.generateMenuButtons();
              this.m1.visible = false;
              this.generateMenuButtons();
            },
            backgroundcolor: "white",
            textcolor: "black",
          },
        ];

        let _treeIssues = [];
        if (root == null) {
          _treeIssues = _.cloneDeep(
            this.myIssues.filter((obj) => {
              return obj.parent == null;
            })
          );
        }
        // only one root
        else {
          _treeIssues = _.cloneDeep(
            this.myIssues.filter((obj) => {
              return obj.name == root.title;
            })
          );
        }
        //console.log(_treeIssues);
        //console.log("Start Tree 1");
        let treeIssues = [];
        for (let loc of _treeIssues) {
          // loc.type_name = "test"
          // loc.btns = []
          let obj = {};
          obj.height = 75;

          let chat_count = 0;
          if (user.chat) {
            if (user.chat.unread) {
              let _count = _.find(user.chat.unread, { issue: loc._id });
              //console.log("user.chat.unread ", user.chat.unread, _count, loc._id);
              if (_count) {
                chat_count = _count.count;
              }
            }
          }
          let svg = "";
          if (chat_count > 0) {
            svg = await this.toSVG(loc.name + " " + String(chat_count));
          } else {
            svg = await this.toSVG(loc.name);
          }

          let d = svg.substring(svg.indexOf("<path d=") + 9, svg.length);
          d = d.substring(0, d.indexOf('"'));

          let width = svg.substring(svg.indexOf("width=") + 7, svg.length);
          //width = width.substring(width.indexOf('"') + 1,width.length)
          width = parseInt(width.substring(0, width.indexOf('"')));

          let height = svg.substring(svg.indexOf("height=") + 8, svg.length);
          height = parseInt(height.substring(0, height.indexOf('"')));

          let icon = { d: d, size: {} };
          // icon.size.h = height * 1.5 - 24;
          // icon.size.w = height * 1.5;
          icon.size.w = width;
          icon.size.h = height;
          // obj.icon = svg;
          obj.icon = icon;
          obj.action = () => {
            //console.log(loc, loc.name);
            this.selectedIssue = loc;
            this.selectedCostcenter = loc.costcenter;
            //this.generateMenuButtons();
            this.m1.visible = false;
            this.generateMenuButtons();
          };
          obj.title = loc.name;
          obj.textcolor = "white";
          obj.backgroundcolor = loc.color;

          obj._id = loc._id;

          obj.btns = [];
          treeIssues.push(obj);
        }

        let tree = _.cloneDeep(
          this.myIssues.filter((obj) => {
            return obj.parent != null;
          })
        );
        let iteration = 0;

        //console.log("Start Tree ");
        while (tree.length > 0) {
          let treeindex = [];
          for (let index in tree) {
            let loc = tree[index];
            let findobj = this.customFilter(treeIssues, loc.parent);

            if (findobj) {
              loc.btns = [];
              //findobj.btns.push(loc)
              let obj = {};
              obj.height = 75;

              let chat_count = 0;
              if (user.chat) {
                if (user.chat.unread) {
                  let _count = _.find(user.chat.unread, { issue: loc._id });
                  //console.log("user.chat.unread ", user.chat.unread, _count, loc._id);
                  if (_count) {
                    chat_count = _count.count;
                  }
                }
              }

              let svg = "";
              if (chat_count > 0) {
                svg = await this.toSVG(loc.name + " " + String(chat_count));
              } else {
                svg = loc.svg;
              }

              //let svg = await this.toSVG(loc.name);
              //let svg = loc.svg;

              let d = svg.substring(svg.indexOf("<path d=") + 9, svg.length);
              d = d.substring(0, d.indexOf('"'));

              let width = svg.substring(svg.indexOf("width=") + 7, svg.length);
              //width = width.substring(width.indexOf('"') + 1,width.length)
              width = parseInt(width.substring(0, width.indexOf('"')));

              let height = svg.substring(
                svg.indexOf("height=") + 8,
                svg.length
              );
              height = parseInt(height.substring(0, height.indexOf('"')));

              let icon = { d: d, size: {} };
              // icon.size.h = height * 1.5 - 17;
              // icon.size.w = height * 1.5;
              icon.size.w = width;
              icon.size.h = height;

              // obj.icon = svg;
              obj.icon = icon;
              obj.action = () => {
                //console.log(loc.name);
                this.selectedIssue = loc;
                this.selectedCostcenter = loc.costcenter;
                //this.generateMenuButtons();
                this.m1.visible = false;
              }; //
              obj.title = loc.name;
              obj._id = loc._id;
              obj.textcolor = "white";
              obj.backgroundcolor = loc.color;
              obj.btns = [];

              findobj.btns.push(obj);
              treeindex.push(index);
            }
          }
          _.pullAt(tree, treeindex);
          iteration++;
          if (iteration > 1000) break;
        }
        //console.log(treeIssues);

        for (let btn of treeIssues) {
          this.menuConfig.btns.push(btn);
        }

        this.calcChatHeight();

        this.loading_data = false;
      } catch (err) {
        //console.log("generate Menu Error: ", err);
        //this.generateMenuButtons();
      }
    },

    calcChatHeight() {
      //console.log(new Date() - this.start_time);
      const posx_chat = this.$refs["chat_ref"].getBoundingClientRect().y;
      //console.log(posx_chat, window.innerHeight);
      if (this.mySelf.roles.includes("admin")) {
        this.chat_height = window.innerHeight - posx_chat - 60;
      } else {
        this.chat_height = window.innerHeight - posx_chat - 38;
      }
      //console.log(this.chat_height);
    },
    async changeUser() {
      await this.getIssues({
        user: this.selectedUser,
        location: this.selectedLocation,
        active: true,
      });

      this.myIssues = this.Issues.filter((obj) => {
        return obj.user.includes(this.selectedUser) && obj.active;
      });
      this.generateMenuButtons();
      await this.getCostcenters({
        location: this.$store.state.selectedLocation._id,
        active: true,
      });
      await this.readUser({
        id: this.selectedUser,
        location_id: this.selectedLocation._id,
      });
      await this.loadEvents(this.isWeekView);
      this.loadIssues(null);
      this.selectedIssueIdx = 0;
      if (this.issue_layers.length > 0) {
        this.selectedIssue = this.issue_layers[0];
        this.selectedCostcenter = this.selectedIssue.costcenter;
      }
    },
    async toggleView() {
      if (this.calendarView == "timeGridDay") {
        this.calendarView = "timeGridWeek";
        this.isWeekView = 1;
        await this.loadEvents(this.isWeekView);
      } else {
        this.calendarView = "timeGridDay";
        this.isWeekView = 0;
        await this.loadEvents(this.isWeekView);
      }
      this.calendarApi.changeView(this.calendarView);
      this.calcCalendarDate();
    },
    clickIssue(issue) {
      //console.log("Click Issue ", issue, this.selectedIssue);
      this.selectedIssue = issue;
      this.loadIssues(issue._id);
      this.selectedCostcenter = this.selectedIssue.costcenter;
    },
    backIssues() {
      let parent_issue = _.find(this.myIssues, { _id: this.lastIssue });
      this.loadIssues(parent_issue.parent);
      //console.log("backIssue: ", parent_issue, this.lastIssue);
      this.selectedIssue = parent_issue;
      this.selectedIssueIdx = _.findIndex(this.issue_layers, {
        _id: parent_issue._id,
      });
      this.selectedCostcenter = this.selectedIssue.costcenter;
    },
    async loadIssues(parent) {
      for (let issue of this.myIssues) {
        //if (!issue.svg) {
        //console.log("toSVG ", issue);
        this.toSVG(issue.name).then((svg) => {
          issue.svg = svg;
          //console.log("toSVG ", issue);
        });
        // }
      }

      let issue_layers = _.filter(this.myIssues, {
        parent: parent,
        active: true,
      });

      if (issue_layers.length > 0) {
        this.lastIssue = parent;
        this.issue_layers = issue_layers;
      }
    },

    async setToday() {
      await this.getMySelf();
      let _time = this.$moment().toDate();
      //console.log("Set Today ", _time);
      this.calendarApi.gotoDate(_time);

      this.$store.state.user.selectedDate = _time;

      this.calcCalendarDate();
      //this.focus = "";
    },

    getEventColor(event) {
      return event.color;
    },

    /*
    calcEventDescription(start,end, description) {
      let diff = this.$moment.diff(this.$moment(end), this.$moment(start));
      console.log(diff);
      let len = 10;
      let resizedDescription = description.substring(0, len);
      return resizedDescription;
    },*/

    async commitEvent(own_event, changeName) {
      let duration = new Date() - this.lastCreateUpdateTime;
      //console.log("duration: ", duration)
      if (duration > this.delayMsForCreateUpdate) {
        //console.log("COMMIT: ", own_event)
        if (own_event.id) {
          if (!this.description) {
            this.description = "";
          }
          this.description = this.description.replace("|", "/");
          let event = {};

          event._id = own_event._id;
          event.user_id = own_event.user_id;
          event.start = own_event.start;
          event.end = own_event.end;
          event.name = own_event.name;
          event.issue = own_event.issue;
          event.costcenter = this.selectedCostcenter;
          event.location = this.selectedLocation._id;

          if (changeName) {
            //event.name = this.compIssueLabel + this.description;

            event.name = this.description;
            event.issue = this.selectedIssue._id;
          }
          //console.log("Start Update ", event);
          await this.updateEvent(event);

          if (this.Event.statusUpdate == "success") {
            const msg = {
              client_id: this.socket.id,
              location: event.location,
              user_id: event.user_id,
            };
            this.socket.send(msg);

            this.lastCreateUpdateTime = new Date();
            //console.log("Update success ", event);
            //await this.loadEvents();
            if (event.name.lastIndexOf("|") >= 0) {
              own_event.title = event.name;
            } else {
              own_event.title = this.compIssueLabel2(event) + event.name;
            }
            own_event.name = event.name;
            own_event.costcenter = event.costcenter;
            own_event.issue = event.issue;

            const issue = _.find(this.myIssues, { _id: event.issue });
            own_event.backgroundColor = issue.color;
            //let calEvent = _.find(this.calendarOptions.events, {id: event._id});
            //calEvent.title = event;
            await this.readUser({
              id: this.selectedUser,
              location_id: this.selectedLocation._id,
            });
          } else {
            this.snackbar_text = "Update failed";
            this.snackbar_error = true;
            this.snackbar = true;
          }
        } else {
          this.snackbar_text = "Please select an event";
          this.snackbar_error = true;
          this.snackbar = true;
        }
      } else {
        this.loadEvents(this.isWeekView);
      }
    },

    async cancel(event) {
      if (event._id != this.trackEvent._id) {
        await this.deleteEvent(event._id);
        //console.log(this.Event)
        if (this.Event.statusDelete == "success") {
          const msg = {
            client_id: this.socket.id,
            location: event.location,
            user_id: event.user_id,
          };
          this.socket.send(msg);

          //console.log("Delete ok")
          for (let idx in this.calendarOptions.events) {
            if (this.calendarOptions.events[idx]._id == event._id) {
              //console.log(this.calendarOptions.events[idx])
              this.calendarOptions.events.splice(idx, 1);
            }
          }
          await this.readUser({
            id: this.selectedUser,
            location_id: this.selectedLocation._id,
          });
          //console.log("Delete all ok")
        } else {
          //console.log("NOT CANCELED");
          //this.loadEvents();
        }
        //this.loadEvents();
        // } else {
        //   console.log("NOT ALLOWED");
        // }
      }
    },

    async loadEvents(week) {
      //let st = new Date();
      //console.log(" Start load Events ", st);
      let today_start = this.$moment(this.selectedDate)
        .startOf("day")
        .format("X");
      let today_end = this.$moment(this.selectedDate).endOf("day").format("X");
      if (week) {
        today_start = this.$moment(this.selectedDate)
          .startOf("isoWeek")
          .format("X");
        today_end = this.$moment(this.selectedDate).endOf("isoWeek").format("X");
      }
      if (this.mySelf.roles.includes("admin")) {
        await this.getEvents({
          from: today_start,
          to: today_end,
          location: this.selectedLocation._id,
        });
      } else {
        await this.getEvents({
          from: today_start,
          to: today_end,
          _id: this.mySelf._id,
        });
      }

      //console.log(" end load Events ", new Date() - st);

      let _events = JSON.parse(JSON.stringify(this.Events));

      if (this.mySelf.roles.includes("admin")) {
        _events = _.filter(_events, { user_id: this.selectedUser });
      }

      for (let _event of _events) {
        try {
          _event.id = _event._id;
          //Compatibility old version
          if (_event.name.lastIndexOf("|") < 0) {
            _event.title = this.compIssueLabel2(_event) + _event.name;
          } else {
            _event.title = _event.name;
          }
          const issue = _.find(this.myIssues, { _id: _event.issue });
          _event.backgroundColor = issue.color;
        } catch (err) {
          console.log("issue not found");
        }
      }
      this.calendarOptions.events = _events;
    },

    async loadTeamEvents() {
      //let st = new Date();
      //console.log(" Start load Events ", this.teamViewDateStart);
      let today_start = this.$moment(this.teamViewDateStart)
        .startOf("day")
        .format("X");
      let today_end = this.$moment(this.teamViewDateStart)
        .endOf("day")
        .format("X");

      let week_start = this.$moment(this.teamViewDateStart).startOf("isoWeek");
      let dayDiff = Number(
        week_start.format("DD") -
          this.$moment(this.teamViewDateStart).format("DD")
      );
      //let week_end = this.$moment(this.selectedDate).endOf("isoWeek");

      let _events = [];

      for (let idx in this.Users) {
        const idxNumber = Number(idx);
        //console.log(this.Users[idx]);
        await this.getEvents({
          from: today_start,
          to: today_end,
          _id: this.Users[idx]._id,
        });
        let events = JSON.parse(JSON.stringify(this.Events));
        // let dayDiff =
        //   week_start.format("DD") - this.$moment(events[0].start).format("DD");

        for (let _event of events) {
          //console.log("_event raw: ", _event.start, dayDiff);

          _event.start = this.$moment(_event.start)
            .add(dayDiff + idxNumber, "day")
            .toDate();
          //_event.start = week_start.add(idx, "day").toDate();
          //_event.start = this.$moment(_event.start).add(1, "day").toDate();
          //ToDo: Cut overnight events
          //_event.end = this.$moment(_event.end).add(1, "day").toDate();
          //_event.end = week_start.add(idx, "day").toDate();
          _event.end = this.$moment(_event.end)
            .add(dayDiff + idxNumber, "day")
            .toDate();
          //console.log("_event: ", _event.start, dayDiff + idxNumber);
          _events.push(_event);
        }
      }
      //console.log(" end load Team Events ", _events);

      //let _events = JSON.parse(JSON.stringify(this.Events));

      for (let _event of _events) {
        try {
          _event.id = _event._id;
          //Compatibility old version
          if (_event.name.lastIndexOf("|") < 0) {
            _event.title = this.compIssueLabel2(_event) + _event.name;
          } else {
            _event.title = _event.name;
          }
          const issue = _.find(this.myIssues, { _id: _event.issue });
          _event.backgroundColor = issue.color;
        } catch (err) {
          //console.log("issue not found");
        }
      }
      this.calendarOptions.events = _events;
    },

    eventClick(info) {
      //console.log("Event Select ", info.event);
      if (!this.teamView) {
        if (this.mySelf) {
          this.recolorEvent();
          this.eventMoved(info);
        } else {
        }
      }
      //console.log("Select Finished")
    },
    async eventMoved(info) {
      if (info.event.id != "") {
        //console.log("Event Moved ", info.event);
        this.last_event = this.calendarOptions.events.find((el) => {
          return el.id == info.event.id;
        });
        //console.log(info.event.start, this.last_event);
        this.last_event.start = info.event.start;
        this.last_event.end = info.event.end;
        this.selectedCostcenter = this.last_event.costcenter;
        //this.last_event.backgroundColor = this.event_color_selected;
        this.last_event.borderColor = this.event_border_color_selected;
        await this.commitEvent(this.last_event, false);
      }
    },

    async eventResize(info) {
      //console.log("Event Resize ");
      this.recolorEvent();
      this.eventMoved(info);
      this.dragging = false;
      //await this.commitEvent(this.last_event, false);
    },

    eventResizeStart(e) {
      //console.log("Event resizeStart")
      this.dragging = true;
    },

    eventDragStart(e) {
      //console.log("Event dragStart")
      this.dragging = true;
    },

    async eventDragStop(e) {
      //console.log("Event dragStop");
    },

    eventDrop(info) {
      //console.log("Event Dropped");
      this.dragging = false;
      this.recolorEvent();
      this.eventMoved(info);
    },

    dateClick(event) {},

    async dateSelect(new_event) {
      let duration = new Date() - this.lastCreateUpdateTime;
      //console.log("duration: ", duration)
      if (duration > this.delayMsForCreateUpdate) {
        //console.log(new_event)
        await this.getMySelf();
        //console.log(this.statusMySelf);
        if (this.statusMySelf === "failure") {
          //this.$router.push({path: "cryptologin"})
          this.logout();
        }

        //console.log("New Event / Date Select", new_event);
        if (!this.description) {
          this.description = "";
        }
        this.description = this.description.replace("|", "/");

        let event = {
          user_id: this.selectedUser,
          start: new_event.start,
          end: new_event.end,
          //name: this.compIssueLabel + this.description,
          name: this.description,
          issue: this.selectedIssue._id,
          costcenter: this.selectedCostcenter,
          location: this.selectedLocation._id,
        };

        await this.createEvent(event);
        if (this.Event.statusCreate == "success") {
          const msg = {
            client_id: this.socket.id,
            location: event.location,
            user_id: event.user_id,
          };
          this.socket.send(msg);

          this.lastCreateUpdateTime = new Date();
          //console.log("Create New success ", event);

          let created_event = JSON.parse(
            JSON.stringify(this.Event.CreatedEvent)
          );
          created_event.title = this.compIssueLabel2(event) + event.name;

          created_event.borderColor = this.event_border_color_selected;
          created_event.id = created_event._id;

          const issue = _.find(this.myIssues, { _id: created_event.issue });
          created_event.backgroundColor = issue.color;

          this.recolorEvent();
          this.calendarOptions.events.push(created_event);

          // this.last_event = _.find(this.calendarOptions.events, {id: created_event.id});
          this.last_event = created_event;
          await this.readUser({
            id: this.selectedUser,
            location_id: this.selectedLocation._id,
          });
        } else {
          this.snackbar_text = "Create failed";
          this.snackbar_error = true;
          this.snackbar = true;
        }
      } else {
        this.loadEvents(this.isWeekView);
      }
    },

    checkCalendarNext() {
      this.swipeDirection = 1;
      if (this.isTimeTracking) {
        this.showTrackingAlert = true;
      } else {
        this.calendarSwitch();
      }
    },

    checkCalendarPrev() {
      this.swipeDirection = 2;
      if (this.isTimeTracking) {
        this.showTrackingAlert = true;
      } else {
        this.calendarSwitch();
      }
    },

    calendarSwitch() {
      if (this.swipeDirection == 1) {
        this.calendarApi.next();
      } else if (this.swipeDirection == 2) {
        this.calendarApi.prev();
      }
      this.$store.state.user.selectedDate = this.calendarApi.getDate();
      this.calcCalendarDate();
    },

    alertTrackingCancel() {
      this.showTrackingAlert = false;
      this.swipeDirection = 0;
      this.showTrackingAlert = false;
    },

    alertTrackingOk() {
      this.stopTimeTracking();
      this.calendarSwitch();
      this.swipeDirection = 0;
      this.showTrackingAlert = false;
    },

    maxSelect(obj) {
      if (
        this.$moment(obj.end - obj.start).format("X") <=
        this.maxSelectHours * 60 * 60
      ) {
        return true;
      } else {
        return false;
      }
    },
    recolorEvent() {
      // if (typeof this.last_event !== "undefined") {
      //   if (this.last_event.backgroundColor == this.event_color_selected) {
      //     const issue = _.find(this.Issues, {_id: this.last_event.issue});
      //     this.last_event.backgroundColor = issue.color;
      //     //this.last_event.backgroundColor = "#00CC22";
      //   }
      // }
      if (typeof this.last_event !== "undefined") {
        if (this.last_event.borderColor == this.event_border_color_selected) {
          //const issue = _.find(this.Issues, {_id: this.last_event.issue});
          this.last_event.borderColor = this.event_border_color_unselected;
          //this.last_event.backgroundColor = "#00CC22";
        }
      }
    },
    overlapAlarm(stillEvent, movingEvent) {
      //console.log("OVERLAP!!!!")
      //return stillEvent.allDay && movingEvent.allDay;
    },
    calcCalendarDate() {
      if (this.calendarView == "timeGridWeek") {
        this.calendarDateStart = this.$moment(this.calendarApi.getDate())
          .startOf("isoWeek")
          .format("DD MMM");
        this.calendarDateEnd =
          " - " +
          this.$moment(this.calendarApi.getDate())
            .endOf("isoWeek")
            .format("DD MMM YYYY");
      } else {
        this.calendarDateStart = this.$moment(
          this.calendarApi.getDate()
        ).format("DD MMM YYYY");
        this.calendarDateEnd = "";
      }

      const subHours = this.windowHeight / 200;
      //console.log(subHours)
      this.calendarApi.scrollToTime(
        this.$moment().subtract(subHours, "hours").format("HH:mm:ss")
      );

      if (
        this.$moment(this.calendarApi.getDate()).format("DD MMM YYYY") ==
        this.$moment().format("DD MMM YYYY")
      ) {
        this.isToday = true;
      } else {
        this.isToday = false;
      }
    },

    async handleResize() {
      //console.log("handle Resize");
      try {
        this.m1.visible = false;
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        this.calendarOptions.height = window.innerHeight - this.calendarHeight;
        // if (this.calendarApi) {
        //   this.calendarApi.scrollToTime(this.$moment().subtract('2','hours').format('HH:mm:ss'));
        // }
        //this.m1.pos.x = this.$refs.menuRef.clientWidth/2;
        this.calcChatHeight();
      } catch (err) {
        //
      }
    },
    compIssueLabel2(targetEvent) {
      const targetIssue = _.find(this.myIssues, { _id: targetEvent.issue });
      let tag = "";
      //console.log("Comp Issue ", targetIssue)
      if (targetIssue && targetIssue._id) {
        let issue = targetIssue;
        //console.log("Comp Issue ", issue)
        while (!_.isUndefined(issue)) {
          tag = issue.name + " | " + tag;
          issue = _.find(this.myIssues, { _id: issue.parent });
          //console.log("Comp Issue & Tag ", issue, tag)
        }
      }
      return tag;
    },
    // isEvent(date) {
    //   const nowEvent = _.find(this.calendarOptions.events, (obj) => {
    //     return this.$moment(obj.start) <= date && this.$moment(obj.end) >= date;
    //   })
    //   if (nowEvent) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    roundTime(start) {
      const diff = start.minute() % this.slotDuration;
      const round = this.slotDuration - diff;
      // console.log(round)
      // console.log(start.minute() % this.slotDuration)
      if (round < diff) {
        start = this.$moment(start).add(round, "minutes").startOf("minute");
      } else {
        start = this.$moment(start).subtract(diff, "minutes").startOf("minute");
      }
      // console.log(start.toDate())
      return start;
    },
    async startTimeTracking() {
      let start = this.roundTime(this.$moment());
      if (!this.isTimeTracking && !this.isNowEvent) {
        this.isTimeTracking = true;
        let ev = {
          start: start.toDate(),
          end: start.add(this.slotDuration, "minutes").toDate(),
        };
        await this.dateSelect(ev);
        //this.trackEvent = _.find(this.calendarOptions.events, {_id: this.last_event.id});
        this.trackEvent = JSON.parse(JSON.stringify(this.last_event));
        // console.log(this.trackEvent)
      }
    },
    async stopTimeTracking() {
      if (this.isTimeTracking) {
        //console.log(this.trackEvent)
        let track = _.find(this.calendarOptions.events, {
          _id: this.trackEvent.id,
        });
        let end = this.roundTime(this.$moment());

        let eventsBetween = _.filter(this.calendarOptions.events, (obj) => {
          //console.log("eventsBetween: ", obj.start, track.start,  end.toDate())
          return (
            this.$moment(obj.start) > this.$moment(track.start) &&
            this.$moment(obj.start) < end
          );
        });
        //console.log("eventsBetween: ",eventsBetween);

        if (eventsBetween.length > 0) {
          eventsBetween = _.orderBy(eventsBetween, "start", "asc");
          //console.log("eventsBetween 2: ", eventsBetween )
          end = this.$moment(eventsBetween[0].start);
        }

        if (this.$moment(track.start) < end) {
          track.end = end.toDate();
          await this.commitEvent(track);
        } else if (this.$moment(track.start) == end) {
          track.end = end.add(this.slotDuration, "minutes").toDate();
          await this.commitEvent(track);
        }
        //console.log(track.end)
        this.trackEvent = {};
        this.isTimeTracking = false;
      }
    },
    handleKeyPressed(key) {
      //console.log(key)
      //console.log(document.activeElement.id);
      if (
        key.key == "Delete" &&
        this.last_event &&
        document.activeElement.id != "text_input_field"
      ) {
        this.cancel(this.last_event);
      }
    },
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Events: ({ event }) => event.Events,
      CreatedEvent: ({ event }) => event.CreatedEvent,
      Event: (state) => state.event,
      selectedDate: ({ user }) => user.selectedDate,
      Issues: ({ issue }) => issue.Issues,
      IssueState: ({ issue }) => issue,
      Costcenters: ({ costcenter }) => costcenter.Costcenters,
      CostcenterState: ({ costcenter }) => costcenter,
      Users: ({ user }) => user.Users,
      ReadUser: ({ user }) => user.ReadUser,
      Locations: ({ location }) => location.Locations,
      moveLocation: ({ location }) => location.moveLocation,
    }),

    // cssVars() {
    //   return {
    //     '--calendarHeight': String(this.calendarOptions.height) + 'px',
    //   }
    // },
    cssVars() {
      if (this.$device.mobile) {
        return {
          "--slotHeight": "25px",
        };
      } else {
        return {
          "--slotHeight": "15px",
        };
      }
    },

    compView() {
      if (this.calendarView == "timeGridWeek") {
        return "Day";
      } else {
        return "Week";
      }
    },
    compViewTeam() {
      if (this.calendarView == "timeGridWeek") {
        return "Day";
      } else {
        return "Team";
      }
    },

    compIssueLabel() {
      let tag = "";
      //console.log("Comp Issue ", this.selectedIssue)
      if (this.selectedIssue && this.selectedIssue._id) {
        let issue = this.selectedIssue;
        // console.log("Comp Issue ", issue)
        while (!_.isUndefined(issue)) {
          tag = issue.name + " | " + tag;
          issue = _.find(this.myIssues, { _id: issue.parent });
          // console.log("Comp Issue & Tag ", issue, tag)
        }
      }
      return tag;
    },

    isAdmin() {
      if (
        this.mySelf.roles.includes("admin") //||
        //this.mySelf.roles.includes("provider")
      ) {
        return true;
      } else {
        return false;
      }
    },

    isNowEvent() {
      let start = this.roundTime(this.$moment());
      const nowEvent = _.find(this.calendarOptions.events, (obj) => {
        return (
          this.$moment(obj.start) <= start.toDate() &&
          this.$moment(obj.end) > start.toDate()
        );
      });
      if (nowEvent) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    selectedLocation(loc) {
      this.$store.state.selectedLocation = loc;
    },
    async selectedDate() {
      //console.log("loadEvents");
      //this.resetLastEvent();
      this.last_event = { name: "" };
      this.loadEvents(this.isWeekView);
    },

    last_event(event, last) {
      //console.log("WATCH LAST EVENT 1 ", event, JSON.stringify(event))
      if (event) {
        if (event._id != last._id) {
          if (event.issue) {
            //console.log("WATCH LAST EVENT ", event, JSON.stringify(event))
            //Compatibility
            if (event.name.lastIndexOf("|") >= 0) {
              this.description = event.name.substring(
                event.name.lastIndexOf("|") + 2,
                event.name.length
              );
            } else {
              this.description = event.name;
            }
            let issue = _.find(this.myIssues, { _id: event.issue });
            //console.log("WATCH LAST EVENT Issue ", issue, this.Issues, event.issue)
            //if (issue) {
            this.loadIssues(issue.parent);
            this.selectedIssue = issue;
            this.selectedIssueIdx = _.findIndex(this.issue_layers, {
              _id: issue._id,
            });

            //this.selectedCostcenter = this.selectedIssue.costcenter;
            //}
          }
        } else {
          //console.log("same event")
        }
      }
    },
    selectedIssue() {
      //ToDo: Nur für Chat Unread Messages, besser direkt manipulieren
      //console.log("selectedIssue ");
      //this.generateMenuButtons();
      this.getUsers();
    },
    // selectedIssue(now, before) {
    //   console.log("Home Issue changed ", now, before);

    //   this.socket.on(String(now._id), (data) => {
    //     try {
    //       console.log("Home Chat Data: ", data);
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   });

    //   let msg = {
    //     issue: now._id,
    //     msg: "Hello from Home",
    //   };
    //   this.socket.emit("chat", msg);
    // },
  },
};
</script>


<style lang='scss'>
// .fc-view-container {
//   height: var(--calendarHeight) !important;
// }
// height: 580px !important;
//.fc-slats td {
// .fc-col-header-cell-cushion {
//   visibility: hidden;
// }
// .fc-col-header-cell-cushion:after {
//   content: var(--slotHeight); //"Test"; //var(--teamViewName);
//   visibility: visible;
// }

.fc-timegrid-slot {
  // height: 26px !important;
  // font-size: 10px;
  height: var(--slotHeight) !important;
  font-size: 10px;
  //background: #FFF !important;
}

.v-application a {
  color: #cfcfcf !important; //Calendar Day Text
}

.fc-event {
  border: 2px solid #3a87ad; // default BORDER color
  background-color: #3a87ad; // default BACKGROUND color
  color: #fff; // default TEXT color
  font-size: 0.7em; // EDIT HERE
  cursor: default;
  padding-left: 2px;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none;
}

.real-inset,
.fc-timegrid-event.fc-timegrid-event,
.fc-event-title {
  white-space: normal;
  width: 100%;
  overflow: hidden;
}
.real-inset,
.fc-timegrid-event.fc-timegrid-event-short,
.fc-event-title {
  white-space: nowrap;
}

.fc-theme-standard {
  // --fc-page-bg-color: rgb(255, 0, 0)  !important;
  // --fc-neutral-bg-color: rgba(255, 0, 0, 0.3) !important;
  // --fc-neutral-text-color: #ca0000 !important;
  --fc-border-color: #5a5a5a !important; //Äusserer Rand
}

.fc-event-main {
  margin-top: -3px;
}

.v-btn--fab.v-size--default {
  height: 20px !important;
  width: 20px !important;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
  width: 85% !important;
}

.v-event-drag-bottom {
  position: absolute;
  left: 40%;
  right: 40%;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
.v-current-time {
  height: 1px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
/*
.v-current-time2 {
  background-color: #ffff00;
  position: absolute;
  pointer-events: none;
}
.v-current-time-grey {
  background-color: #cccccc;
  position: absolute;
  pointer-events: none;
}
*/
.box {
  border: solid 1px red;
}
.greybox {
  border: solid 1px #5a5a5a;
}
.v-event-draggable {
  font-size: 9px;
}

/*
.purplesquare {
  overflow-y: hidden;
  position: absolute;
  top: 100px;
  left: 100px;
  width: 200px;
  height: 200px;

  z-index: 100;
}*/

.v-card {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.v-sheet {
  background-color: #000000 !important;
  color: rgb(203, 203, 203, 0.9) !important; //Schrift
}


/* SCROLLBAR - START - */

/* width */



.radial-menu-main {
  visibility: visible !important;
  position: absolute !important;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3a3a3a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5a5a5a;
}
</style>
    // 
    // overflow: hidden;
    //   position: absolute;
    // top: 100px;
    // left: 200px;
    // width: 200px;
    // height: 200px;

