import Vue from 'vue'
import Vuetify from 'vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

const opts = {
	theme: {
		dark: true,
    themes: {
      light: {
        primary: '#125a9e',
        secondary: '#a6e3fa',
        anchor: '#8c9eff',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
			},
			dark: {
        primary: '#62AaEe',
        secondary: '#a6e3fa',
        anchor: '#8c9eff',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      }
    }
  },
  icons: {
    iconfont: 'mdi',
  },
}

export default new Vuetify(opts)