<template>
  <div class="user" v-if="mySelf">
    <v-card flat>
      <v-row>
        <v-col>
          <v-data-table
            :height="windowHeight"
            :headers="headers_user"
            :items="Users"
            sort-by="locations"
            class="elevation-1"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Users {{ locationCode }}</v-toolbar-title>

                <!--<v-divider class="mx-4" inset vertical></v-divider>-->
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog_user" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{ formTitleUser }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemUser.displayname"
                          label="Nickname"
                        ></v-text-field>
                        <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemUser.fullname"
                          label="Name"
                        ></v-text-field>
                        <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemUser.email"
                          label="Email"
                        ></v-text-field>
                        <v-checkbox
                          dense
                          v-model="editedItemUser.activated"
                          label="Activated"
                        ></v-checkbox>
                        <div v-if="editedItemUser.options">
                          <v-text-field
                            type="Number"
                            dense
                            flat
                            v-model="editedItemUser.options.flextime_start"
                            label="flextime_start"
                          ></v-text-field>
                          <v-text-field
                            type="Number"
                            dense
                            flat
                            v-model="editedItemUser.options.flextime_end"
                            label="flextime_end"
                          ></v-text-field>
                          <v-text-field
                            type="Number"
                            dense
                            flat
                            v-model="editedItemUser.options.core_start"
                            label="core_start"
                          ></v-text-field>
                          <v-text-field
                            type="Number"
                            dense
                            flat
                            v-model="editedItemUser.options.core_end"
                            label="core_end"
                          ></v-text-field>

                          <v-text-field
                            type="String"
                            dense
                            flat
                            v-model="
                              editedItemUser.options.hours_per_isoweekday_array[
                                last_hours_per_isoweekday_array
                              ].week_hours
                            "
                            label="hours_per_isoweekday"
                          ></v-text-field>
                          <v-text-field
                            type="Date"
                            dense
                            flat
                            v-model="
                              editedItemUser.options.hours_per_isoweekday_array[
                                last_hours_per_isoweekday_array
                              ].valid_from
                            "
                            label="hours_per_isoweekday Array valid from"
                          ></v-text-field>
                          <v-text-field
                            type="Number"
                            dense
                            flat
                            v-model="
                              editedItemUser.options.hours_per_isoweekday_array[
                                last_hours_per_isoweekday_array
                              ].overtime_init
                            "
                            label="overtime_init"
                          ></v-text-field>
                          <v-radio-group v-model="editedItemUser.roles[0]">
                            <v-radio
                              v-for="role of roles"
                              :key="role"
                              :label="`${role}`"
                              :value="role"
                            ></v-radio>
                          </v-radio-group>
                          <!-- <v-text-field
                            type="String"
                            dense
                            flat
                            v-model="editedItemUser.options.break_per_isoweekday"
                            label="break_per_isoweekday"
                          ></v-text-field> -->
                          <!-- <v-text-field
                            type="Date"
                            dense
                            flat
                            v-model="editedItemUser.options.start_date"
                            label="start_date"
                          ></v-text-field> -->
                        </div>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeUser"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="saveUser"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.activation_token="{ item }">
              {{ checkActivationToken(item) }}
            </template>
            <template v-slot:item.options="{ item }">
              <div class="caption">{{ JSON.stringify(item.options) }}</div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItemUser(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="deleteItemUser(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule, emailRule } from "../utils/rules";

export default {
  name: "user",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      emailRule,

      dialog_delete: false,

      dialog_user: false,
      headers_user: [
        { text: "Actions", value: "actions", sortable: false },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "fullname",
        },
        { text: "Nickname", value: "displayname" },
        { text: "Email", value: "email" },
        { text: "roles", value: "roles" },
        { text: "locations", value: "locations" },
        { text: "options", value: "options" },
        { text: "activated", value: "activated" },
        { text: "Email Activated", value: "activation_token" },
      ],

      editedIndexUser: -1,
      defaultItemUser: {
        displayname: "",
        fullname: "",
        email: "",
        password: "",
        phone: "",
        roles: [],
        activated: true,
        options: {
          flextime_start: 8,
          flextime_end: 20,
          core_start: 9,
          core_end: 18,
          //hours_per_isoweekday: [8, 8, 8, 8, 6.5, 0, 0],
          hours_per_isoweekday_array: [
            {
              week_hours: [8, 8, 8, 8, 6.5, 0, 0],
              valid_from: new Date(),
              overtime_init: 0,
            },
          ],
          //break_per_isoweekday: [0.5,0.5,0.5,0.5,0.5,0,0]
        },
      },
      last_hours_per_isoweekday_array: 0,
      editedItemUser: {},
      snackbar: false,
      snackbar_text: "",
      roles: ["user", "clientAdmin"],
    };
  },
  created() {
    this.editedItemUser = this.defaultItemUser;
    if (this.mySelf.roles.includes("admin")) {
      this.roles.push("admin");
    }
  },
  async mounted() {
    await this.getUsers({});
    await this.getLocation({});

    for (let user of this.Users) {
      try {
        //user.options.start_date = this.$moment(user.options.start_date).format("YYYY-MM-DD")
        user.options.hours_per_isoweekday = JSON.stringify(
          user.options.hours_per_isoweekday
        );
        for (let _el of user.options.hours_per_isoweekday_array) {
          _el.week_hours = JSON.stringify(_el.week_hours);
          _el.valid_from = this.$moment(_el.valid_from).format("YYYY-MM-DD");
        }

        //user.options.break_per_isoweekday = JSON.stringify(user.options.break_per_isoweekday);
        user.locations = [];
        for (let location of this.Locations) {
          if (location.user.includes(user._id)) {
            user.locations.push(location.name);
          }
        }
      } catch (err) {}
    }
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Users: ({ user }) => user.Users,
      UserState: ({ user }) => user,
      Locations: ({ location }) => location.Locations,
    }),

    windowHeight() {
      return String(window.innerHeight - 180) + "px";
    },

    formTitleUser() {
      return this.editedIndexUser === -1 ? "New User" : "Edit User";
    },

    locationCode() {
      if (this.Locations.length == 1) {
        return "(Invitation Code: " + this.Locations[0].code + ")";
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions("user", [
      "getUsers",
      "createUser",
      "readUser",
      "updateUser",
      "deleteUser",
    ]),
    ...mapActions("location", ["getLocation"]),

    checkActivationToken(item) {
      return item.activation_token == null;
    },
    /*loadUsers(a) {
      this.companyUsers = _.filter(this.Users, { location: a._id });
      this.editedItemUser.location = a._id;
    },*/

    async editItemUser(item) {
      // console.log("editItem");
      this.editedIndexUser = this.Users.indexOf(item);
      this.editedItemUser = Object.assign({}, item);
      this.last_hours_per_isoweekday_array =
        this.editedItemUser.options.hours_per_isoweekday_array.length - 1;
      this.dialog_user = true;
    },

    async deleteItemUser(item) {
      const index = this.Users.indexOf(item);
      await this.deleteUser(item._id);
      if (this.UserState.statusDelete == "success") {
        this.Users.splice(index, 1);
      } else {
        this.snackbar_text = "Already deleted";
        this.snackbar = true;
      }
    },

    closeUser() {
      this.dialog_user = false;
      this.$nextTick(() => {
        this.editedItemUser = Object.assign({}, this.defaultItem);
        this.editedIndexUser = -1;
      });
    },

    async saveUser() {
      //console.log("saveUser");

      //console.log(this.editedItemUser);
      if (!this.editedItemUser.options) {
        this.editedItemUser.options = {};
      } else {
        //console.log(this.editedItemUser.options);

        if (
          typeof this.editedItemUser.options.hours_per_isoweekday == "string"
        ) {
          this.editedItemUser.options.hours_per_isoweekday = JSON.parse(
            this.editedItemUser.options.hours_per_isoweekday
          );
        }
        for (let _el of this.editedItemUser.options
          .hours_per_isoweekday_array) {
          if (typeof _el.week_hours == "string") {
            _el.week_hours = JSON.parse(_el.week_hours);
          }
          if (typeof _el.valid_from == "string") {
            _el.valid_from = this.$moment(
              _el.valid_from,
              "YYYY-MM-DD"
            ).toDate();
          }
        }

        //this.editedItemUser.options.break_per_isoweekday = JSON.parse(this.editedItemUser.options.break_per_isoweekday);

        //console.log(this.editedItemUser.options)
      }
      if (this.editedIndexUser > -1) {
        await this.updateUser(this.editedItemUser);
        if (this.UserState.statusUpdate == "success") {
          this.editedItemUser._id = this.UserState.User._id;
          this.editedItemUser.updatedAt = this.$moment(
            this.UserState.User.updatedAt
          ).format("DD-MM-YYYY HH:mm");
          Object.assign(this.Users[this.editedIndexUser], this.editedItemUser);
        } else {
          console.log("Update error");
          this.snackbar_text = this.UserState.User.message;
          this.snackbar = true;
        }
      } else {
        await this.createUser(this.editedItemUser);
        if (this.UserState.statusCreate == "success") {
          this.editedItemUser._id = this.UserState.User._id;
          //this.editedItemUser.roles = [this.UserState.roles];
          this.editedItemUser.activated = true;
          this.editedItemUser.updatedAt = this.$moment(
            this.UserState.User.updatedAt
          ).format("DD-MM-YYYY HH:mm");
          this.Users.push(this.editedItemUser);
        } else {
          this.snackbar_text = this.snackbar_text = this.UserState.User.message;
          this.snackbar = true;
        }
      }

      this.closeUser();
    },
  },
  watch: {},
};
</script>

<style scoped>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
</style>