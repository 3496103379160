<template>
  <div class="report" v-if="mySelf">
    <v-card flat>
      <!-- <v-progress-circular
        indeterminate
        :size="50"
        :width="7"
        color="secondary"
        v-if="loading_data"
      >
      </v-progress-circular> -->

      <v-row>
        <v-col cols="auto" class="pa-7">
          <!-- -->
          <v-row>
            <v-col>
              <v-text-field
                dense
                type="date"
                v-model="selected_date"
                v-on:click="calcReport = false"
                label="from"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                type="date"
                v-model="selected_date_to"
                v-on:click="calcReport = false"
                label="to"
              ></v-text-field>
            </v-col>
            <!--<v-col>
              <v-checkbox
                dense
                v-model="checkbox_month"
                v-on:click="calcReport = false"
                :label="'Whole ' + $moment(selected_date).format('MMM YY')"
              ></v-checkbox>
            </v-col>-->
            <v-col>
              <v-btn color="orange" small dark @click="setToFullMonth">
                Full Month
              </v-btn>
            </v-col>
            <!-- <v-col>
              <v-checkbox
                dense
                :disabled="Locations.length <= 1"
                v-model="checkbox_location"
                v-on:click="calcReport = false"
                label="Location"
              ></v-checkbox>
            </v-col> -->
            <v-col>
              <v-autocomplete
                :disabled="Locations.length <= 1"
                dense
                :items="Locations"
                item-text="name"
                item-value="_id"
                v-model="selected_location"
                v-on:click="calcReport = false"
                label="Location"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-checkbox
                dense
                v-model="checkbox_project"
                v-on:click="calcReport = false"
                label="Project"
              ></v-checkbox>
            </v-col>

            <v-col v-if="checkbox_project">
              <v-autocomplete
                dense
                :items="Issues"
                item-text="name"
                item-value="_id"
                v-model="selected_project"
                v-on:click="calcReport = false"
                label="Project"
              ></v-autocomplete>
            </v-col>

            <v-col>
              <v-checkbox
                dense
                v-model="checkbox_costcenter"
                v-on:click="calcReport = false"
                label="Costcenter"
              ></v-checkbox>
            </v-col>
            <v-col v-if="checkbox_costcenter">
              <v-autocomplete
                dense
                :items="Costcenters"
                item-text="name"
                item-value="_id"
                v-model="selected_costcenter"
                v-on:click="calcReport = false"
                label="Costcenter"
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="
                mySelf.roles.includes('admin') ||
                mySelf.roles.includes('clientAdmin')
              "
            >
              <v-checkbox
                dense
                v-model="checkbox_user"
                v-on:click="calcReport = false"
                label="User"
              ></v-checkbox>
            </v-col>
            <v-col v-if="checkbox_user">
              <v-autocomplete
                dense
                :items="Users"
                item-text="fullname"
                item-value="_id"
                v-model="selected_user"
                v-on:click="calcReport = false"
                label="User"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!calcReport">
              <v-btn color="blue" small dark @click="generateExcel()">
                Generate Detail Report
              </v-btn>
            </v-col>
            <v-col v-if="!calcReport">
              <v-btn color="blue" small dark @click="generateExcel2()">
                Generate Project Level Report
              </v-btn>
            </v-col>
            <v-col v-if="!calcReport && checkbox_costcenter">
              <v-btn color="blue" small dark @click="generateExcel4()">
                Generate CostCenter Day Report
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="orange" small dark @click="showChart = !showChart">
                Toggle Table/Chart
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <!--   <v-col v-if="!calcReport">
              <v-btn color="blue" small dark @click="generateExcel3()">
                Generate All Projekt Report from Date
              </v-btn>
            </v-col>-->

            <v-col v-if="calcReport" class="pt-0">
              <download-excel
                :data="json_data"
                :fields="json_fields"
                :header="json_title"
                worksheet="Report"
              >
                <v-btn color="success" small elevation="1"
                  >Download Excel</v-btn
                >
              </download-excel>
            </v-col>
            <v-col
              v-if="calcReport && selected_costcenter && selected_user && report_filter.report == 4"
              class="pt-0"
            >
              <v-btn
                color="success"
                small
                elevation="1"
                v-on:click="downloadExcelReport4"
                >Download Report</v-btn
              >
            </v-col>
            <v-col
              v-if="calcReport && selected_costcenter && selected_user && report_filter.report == 1"
              class="pt-0"
            >
              <v-btn
                color="success"
                small
                elevation="1"
                v-on:click="downloadExcelReport1Monthly"
                >Download Report</v-btn
              >
            </v-col>
            <v-col v-if="calcReport" class="pt-0">
              <v-btn
                color="error"
                small
                elevation="1"
                v-on:click="calcReport = false"
                >Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="calcReport">
        <v-col class="mx-4 font-weight-bold pt-0">
          {{ json_title }}
        </v-col>
      </v-row>
      <v-overlay :value="loading_data" :opacity="0.5">
        <v-progress-circular
          color="blue"
          indeterminate
          size="200"
        ></v-progress-circular>
      </v-overlay>
      <v-row v-if="calcReport">
        <v-col cols="12" md="4" v-if="showChart">
          <apexchart
            type="donut"
            :options="chartOptions"
            :series="chartSeries"
          ></apexchart>
        </v-col>
        <v-col v-if="!showChart">
          <v-data-table
            :headers="json_header"
            :items="json_data"
            :height="windowHeight"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
const ExcelJS = require("exceljs");
import { saveAs } from "file-saver";

export default {
  name: "report",
  components: {},
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      loading_data: true,

      json_data: [],
      json_header: [],
      json_fields: {},
      json_title: "",

      calcReport: false,
      selected_date: this.$moment().format("YYYY-MM-DD"),
      selected_date_to: this.$moment().format("YYYY-MM-DD"),
      selected_project: "",
      checkbox_project: false,
      checkbox_month: false,
      checkbox_user: false,
      selected_user: "",
      checkbox_costcenter: false,
      selected_costcenter: "",
      checkbox_location: true,
      selected_location: "",

      chartSeries: [],
      chartOptions: {
        labels: [],
        dataLabels: {
          enabled: true,
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            return (
              w.config.labels[seriesIndex] +
              " " +
              w.config.series[seriesIndex] +
              "h"
            );
          },
        },
        legend: {
          show: false,
        },
      },
      showChart: false,
      report_filter: {},
      selected_project_name: {},
    };
  },
  async mounted() {
    // const data = [
    //   [1, 2, 3],
    //   [true, false, null, "sheetjs"],
    //   ["foo", "bar", new Date("2014-02-19T14:30Z"), "0.3"],
    //   ["baz", null, "qux"],
    // ];
    // var buffer = xlsx.build([{ name: "mySheetName", data: data }]); // Returns a buffer
    // console.log("excel: ", buffer);

    // await this.loadMachines();
    // await this.loadMachineContracts();
    if (this.mySelf.roles.includes("admin")) {
      await this.getUsers({});
    }

    // this.generateUser();
    // this.generateMachine();
    // this.generateBuyables();
    // this.generateIssuedCoins();

    await this.getIssues();
    await this.getLocation({});
    await this.getCostcenters({ active: true });

    if (this.Locations.length > 0) {
      this.selected_location = this.Locations[0]._id;
    }
    this.loading_data = false;
    //
    //console.log(this.Web3.SitesRegistryContract)

    //this.generateExcel();
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Event: (state) => state.event,
      Issues: ({ issue }) => issue.Issues,
      IssueState: ({ issue }) => issue,
      // Web3: (web3) => web3.web3,
      // MachineContracts: ({ machineContract }) =>
      // machineContract.MachineContracts,
      Users: ({ user }) => user.Users,
      // Sites: ({ user }) => user.Sites,
      // selectedSite: ({ user }) => user.selectedSite,
      Costcenters: ({ costcenter }) => costcenter.Costcenters,
      CostcenterState: ({ costcenter }) => costcenter,
      Locations: ({ location }) => location.Locations,
    }),

    windowHeight() {
      return String(window.innerHeight - 395) + "px";
    },
  },
  methods: {
    // ...mapActions("machineContract", ["getMachineContracts"]),
    ...mapActions("user", ["getUsers"]),
    ...mapActions("event", ["getEventsReport"]),
    ...mapActions("issue", ["getIssues"]),
    ...mapActions("costcenter", ["getCostcenters"]),
    ...mapActions("location", ["getLocation"]),

    async loadReportEvents(filter) {
      await this.getEventsReport(filter);
      //console.log(this.Event.Events);
    },

    async generateExcel() {
      this.initReport();

      this.json_title =
        "Detailed Report: " +
        this.$moment(this.selected_date, "YYYY-MM-DD").format("DD MMM YYYY");

      this.calcReportTitleFilter();
      this.report_filter.report = 1;

      await this.loadReportEvents(this.report_filter);

      let report = [];

      let filteredEvents = _.orderBy(this.Event.Events.events, [
        "user_id.fullname",
        "start",
      ]);

      for (let event of filteredEvents) {
        let obj = {};
        obj.location = event.location.name;
        obj.User = event.user_id.fullname;

        let maxIssue;
        for (maxIssue = 1; maxIssue <= 5; maxIssue++) {
          let n2 = "issue" + String(maxIssue);
          //console.log("maxIssue: ", maxIssue, n2, event[n2]);
          if (!event[n2]) {
            maxIssue--;
            break;
          } else if (event[n2].name.length == 0) {
            maxIssue--;
            break;
          }
        }
        //console.log(maxIssue);

        for (let i = maxIssue; i > 0; i--) {
          let n = "issue" + String(maxIssue - i + 1);
          let n2 = "issue" + String(i);
          if (event[n2]) {
            obj[n] = event[n2].name;
          }
        }

        //console.log(event)
        if (event.costcenter) {
          if (event.costcenter.name) {
            obj.Costcenter = event.costcenter.name;
          } else {
            event.costcenter.name = "not found";
          }
        }
        obj.Description = event.name;
        if (obj.Description.lastIndexOf("|") > 0) {
          obj.Description = obj.Description.substring(
            obj.Description.lastIndexOf("|") + 2,
            obj.Description.length
          );
        }
        obj.StartDate = this.$moment(event.start).format("DD.MM.YYYY");
        obj.StartTime = this.$moment(event.start).format("HH:mm");
        obj.EndDate = this.$moment(event.end).format("DD.MM.YYYY");
        obj.EndTime = this.$moment(event.end).format("HH:mm");
        obj.Hours =
          (this.$moment(event.end) - this.$moment(event.start)) / 3600 / 1000;
        obj.Hours = String(obj.Hours).replace(".", ",");

        report.push(obj);
      }
      //console.table(report);
      this.generateHeader(report);
      this.json_data = _.orderBy(report, ["start"], ["desc"]);
      let dataSum = 0.0;
      for (let data of this.json_data) {
        
        data.HoursFloat = String(data.Hours).replace(",", ".");
        //data.HoursFloat = parseFloat(data.Hours);
        dataSum += Number(data.HoursFloat);
      }
      //console.table(this.json_data);
      this.json_title +=
        "   -- Gesamt Stunden: " + dataSum;
      //console.log(this.json_data)

      // Chart
      this.chartOptions.labels = [];
      this.chartSeries = [];

      this.calcReport = true;
      this.loading_data = false;
    },

    async generateExcel2() {
      this.initReport();
      this.json_title =
        "Project Level Report: " +
        this.$moment(this.selected_date, "YYYY-MM-DD").format("DD MMM YYYY");

      this.calcReportTitleFilter();
      this.report_filter.report = 3;

      await this.loadReportEvents(this.report_filter);

      this.generateHeader(this.Event.Events.results3);
      this.json_data = this.Event.Events.results3;
      if (this.checkbox_costcenter) {
        const _selected_costcenter = _.find(this.Costcenters, {
          _id: this.selected_costcenter,
        });

        if (
          this.$moment(_selected_costcenter.start_rec_date) >=
          this.$moment(this.selected_date)
        ) {
          this.json_data.push({
            fullname: "Project effort before recording",
            costcenter: _selected_costcenter.name,
            duration: String(_selected_costcenter.start_hours).replace(".", ","),
          });
        }
      }

      this.json_data = _.orderBy(this.json_data, [
        "fullname",
        "issue",
        "productive",
      ]);
      for (let data of this.json_data) {
        data.duration2 = parseFloat(data.duration);
        data.duration = String(data.duration).replace(".", ",")
      }
      //console.table(this.json_data);
      this.json_title +=
        "   -- Gesamt Stunden: " + _.sumBy(this.json_data, "duration2");
      this.calcReport = true;

      // Chart
      this.chartOptions.labels = [];
      this.chartSeries = [];
      for (let _data of this.json_data) {
        let label = _data.issue5;
        if (!label) label = _data.issue4;
        if (!label) label = _data.issue3;
        if (!label) label = _data.issue2;
        if (!label) label = _data.issue1;
        if (label) {
          this.chartOptions.labels.push(label);
          this.chartSeries.push(_data.duration);
        }
      }

      this.loading_data = false;
    },

    calcReportTitleFilter() {
      this.json_title +=
        " to " +
        this.$moment(this.selected_date_to, "YYYY-MM-DD").format("DD MMM YYYY");
      this.report_filter.from = this.$moment(
        this.selected_date,
        "YYYY-MM-DD"
      ).format("X");
      this.report_filter.to = this.$moment(this.selected_date_to, "YYYY-MM-DD")
        .endOf("day")
        .format("X");

      this.selected_project_name = _.find(this.Issues, {
        _id: this.selected_project,
      });
      if (this.checkbox_project) {
        this.json_title += " of Project " + this.selected_project_name.name;
        this.report_filter.issue = this.selected_project;
      }

      const selected_user_name = _.find(this.Users, {
        _id: this.selected_user,
      });
      if (this.checkbox_user) {
        this.json_title += " and User " + selected_user_name.fullname;
        this.report_filter.user_id = this.selected_user;
      }

      const selected_location_name = _.find(this.Locations, {
        _id: this.selected_location,
      });
      if (this.checkbox_location) {
        this.json_title += " and Location " + selected_location_name.name;
        this.report_filter.location = this.selected_location;
      }

      if (this.checkbox_costcenter) {
        const _selected_costcenter = _.find(this.Costcenters, {
          _id: this.selected_costcenter,
        });
        this.json_title += " and Costcenter " + _selected_costcenter.name;
        this.report_filter.costcenter = this.selected_costcenter;
      }
    },

    initReport() {
      this.loading_data = true;
      this.showChart = false;
      this.json_data = [];
      this.calcReport = false;
      this.report_filter = {};
    },

    async generateExcel4() {
      if (this.checkbox_costcenter) {
        this.initReport();
        this.json_title =
          "CostCenter Day Level Report: " +
          this.$moment(this.selected_date, "YYYY-MM-DD").format("DD MMM YYYY");

        this.calcReportTitleFilter();
        this.report_filter.report = 4;

        //console.log("report4 filter: ", this.report_filter);
        await this.loadReportEvents(this.report_filter);

        this.generateHeader(this.Event.Events.results4);
        this.json_data = this.Event.Events.results4;


        this.json_data = _.orderBy(this.json_data, ["date", "costcenter"]);
        for (let data of this.json_data) {
          data.duration2 = parseFloat(data.duration) / 3600 / 1000;
          data.duration = String(data.duration2).replace(".", ",")
          data.date = this.$moment(data.date, "YYYY-MM-DD").format(
            "ddd DD.MM.YYYY"
          );
        }
        //console.table(this.json_data);
        this.json_title +=
          "   -- Gesamt Stunden: " + _.sumBy(this.json_data, "duration2");

        // Chart
        //if (this.checkbox_costcenter) {
        this.chartOptions.labels = [];
        this.chartSeries = [];
        for (let _data of this.json_data) {
          let label = _data.date;
          if (label) {
            this.chartOptions.labels.push(label);
            this.chartSeries.push(_data.duration);
          }
        }

        this.calcReport = true;
      }
      this.loading_data = false;
    },
    
    async downloadExcelReport4() {
      if (this.checkbox_user) {
        let fileName = "report4.xlsx";

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("My Sheet", {
          properties: { tabColor: { argb: "FFC0000" } },
        });
        //console.log(this.json_data[0]);

        // const rows = [
        //   [5,'Bob',new Date()], // row by array
        //   {id:6, name: 'Barbara', dob: new Date()}
        // ];

        let _row = worksheet.addRow(["Work Report"]);
        worksheet.mergeCells("A1:E1");
        //worksheet.getCell("A1").font = { size: 20 };
        _row._cells[0].font = { bold: true };
        _row._cells[0].alignment = { horizontal: "center" };
        _row._cells[0].fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F0F0F0" },
        };
        worksheet.addRow();

        let _duration = this.$moment(this.report_filter.to, "X").diff(
          this.$moment(this.report_filter.from, "X"),
          "months"
        );
        //console.log(_duration);

        //console.log(this.$moment(this.report_filter.to, "X"));

        if (_duration < 1) {
          worksheet.addRow([
            "Month",
            this.$moment(this.report_filter.from, "X").format("MMMM YYYY"),
          ]);
        } else {
          worksheet.addRow([
            "from Month",
            this.$moment(this.report_filter.from, "X").format("MMMM YYYY"),
          ]);
          worksheet.addRow([
            "to Month",
            this.$moment(this.report_filter.to, "X").format("MMMM YYYY"),
          ]);
        }

        worksheet.addRow(["CostCenter", this.json_data[0].costcenter]);

        //console.log(this.selected_project_name);
        if (this.checkbox_project) {
          worksheet.addRow(["Work Package", this.selected_project_name.name]);
        }
        worksheet.addRow(["Employee", this.json_data[0].user]);
        worksheet.addRow(["Employer", this.json_data[0].location]);
        worksheet.addRow();
        worksheet.addRow();
        let _last_row_title = worksheet.addRow(["Date", "Hours"]);
        //console.log(_last_row_title);
        _last_row_title._cells[0].font = { bold: true };
        _last_row_title._cells[1].font = { bold: true };
        worksheet.columns = [
          { key: "date", width: 15 },
          { key: "duration", width: 15 },
          { width: 15 },
          { width: 15 },
          { width: 15 },
        ];

        worksheet.addRows(this.json_data);
        _row = worksheet.addRow(["Sum", ""]);

        _row._cells[1].value = {
          formula:
            "SUM(B" +
            String(_last_row_title._number + 1) +
            ":B" +
            String(_row._number - 1) +
            ")",
        };
        _row._cells[0].font = { bold: true };
        _row._cells[1].font = { bold: true };

        for (let i = _last_row_title._number + 1; i < _row._number; i++) {
          let _arr = ["A", "B"];
          for (let _cell of _arr) {
            worksheet.getCell(_cell + i).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        }

        worksheet.addRow();
        worksheet.addRow();
        worksheet.addRow();
        worksheet.addRow();
        worksheet.addRow();
        worksheet.addRow();
        _row = worksheet.addRow([
          "Date & Signature Employee",
          ,
          ,
          "Date & Signature Employer",
        ]);

        let _arr = ["A", "B", "D", "E"];
        for (let _cell of _arr) {
          worksheet.getCell(_cell + _row._number).border = {
            top: { style: "thin" },
          };
        }

        const buffer = await workbook.xlsx.writeBuffer();
        var blob = new Blob([buffer], {
          //type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",+
          type: "application/msexcel",
        });

        saveAs(blob, fileName);
      }
    },

    async downloadExcelReport1Monthly() {
      if (this.checkbox_user) {

        let fileName = "report-detailed-monthly.xlsx";

        const workbook = new ExcelJS.Workbook();

        let _duration = this.$moment(this.report_filter.to, "X").diff(
          this.$moment(this.report_filter.from, "X"),
          "months"
        );
        // console.log(_duration);
        //console.log(this.$moment(this.report_filter.to, "X"));
       
        let filtered_data = [];
        for (let _month = 0; _month <= _duration; _month++) {
          let obj = {};
          obj.month_start = this.$moment(this.report_filter.from, "X").add(_month, "month").startOf("month");
          // obj.month_end = this.$moment(this.report_filter.from, "X").add(_month, "month").endOf("month");
          filtered_data.push(obj);
        }
        
        for (let month of filtered_data) {
          
          let json_data_filtered = this.json_data.filter(obj => {
            return Number(this.$moment(obj.StartDate, "DD.MM.YYYY").startOf("month").format("X")) == Number(month.month_start.format("X"));
          })
          // console.log(month.month_start.toDate(), json_data_filtered[0] );
          if (json_data_filtered.length > 0) {
            // console.log(json_data_filtered);
            
            const worksheet = workbook.addWorksheet(month.month_start.format("MM YYYY"), {
              properties: { }, //tabColor: { argb: "FFC0000" } },
            });
            //console.log(json_data_filtered[0]);


            let _row = worksheet.addRow(["Work Report"]);
            worksheet.mergeCells("A1:F1");
            //worksheet.getCell("A1").font = { size: 20 };
            _row._cells[0].font = { bold: true };
            _row._cells[0].alignment = { horizontal: "center" };
            _row._cells[0].fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "F0F0F0" },
            };
            worksheet.addRow();

            worksheet.addRow([
              "Month",
              month.month_start.format("MMMM YYYY"),
            ]);


            worksheet.addRow(["Project", json_data_filtered[0].Costcenter]);

            //console.log(this.selected_project_name);
            if (this.checkbox_project) {
              worksheet.addRow(["Work Package", this.selected_project_name.name]);
            }
            worksheet.addRow(["Worker", json_data_filtered[0].User]);
            worksheet.addRow(["Company", json_data_filtered[0].location]);
            worksheet.addRow();
            worksheet.addRow();
            let _last_row_title = worksheet.addRow(["Date", "StartTime", "EndTime", "AP", "Description", "Hours"]);
            //console.log(_last_row_title);
            for (let i = 0; i < 6; i++) {
              _last_row_title._cells[i].font = { bold: true };
            }
            
            json_data_filtered.forEach(obj => {
              obj.HoursNumber = Number(obj.HoursFloat);
            })

            worksheet.columns = [
              { key: "StartDate", width: 12 },
              { key: "StartTime", width: 10 },
              { key: "EndTime", width: 10 },
              { key: "issue3", width: 10 },
              { key: "Description", width: 60 },
              { key: "HoursNumber", width: 8 },
            ];

            worksheet.addRows(json_data_filtered);
            _row = worksheet.addRow(["Sum", "", "", "", "", "", ""]);

            _row._cells[5].value = {
              formula:
                "SUM(F" +
                String(_last_row_title._number + 1) +
                ":F" +
                String(_row._number - 1) +
                ")",
            };
            _row._cells[0].font = { bold: true };
            _row._cells[5].font = { bold: true };

            for (let i = _last_row_title._number + 1; i < _row._number; i++) {
              let _arr = ["A", "B", "C", "D", "E", "F"];
              for (let _cell of _arr) {
                worksheet.getCell(_cell + i).border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
              }
            }
          }

        }

        const buffer = await workbook.xlsx.writeBuffer();
        var blob = new Blob([buffer], {
          //type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",+
          type: "application/msexcel",
        });

        saveAs(blob, fileName);
      }
    },

    async generateHeader(items) {
      let arr = [];
      this.json_fields = {};
      if (items.length > 0) {
        let foundIdx = 0;
        let itemLength = 0;
        for (let idx in items) {
          //console.log("Issue ", idx, items[idx] ,Object.keys(items[idx]).length)
          if (itemLength < Object.keys(items[idx]).length) {
            itemLength = Object.keys(items[idx]).length;
            foundIdx = idx;
          }
        }
        //console.log("Longest Issue ", items[foundIdx], foundIdx)
        for (let item of Object.keys(items[foundIdx])) {
          let obj = {};
          obj.text = this.$t(String(item));
          obj.value = item;
          this.json_fields[this.$t(String(item))] = item;
          arr.push(obj);
        }
        this.json_header = arr;
        //console.log(this.json_header)
      }
    },

    setToFullMonth() {
      this.selected_date = this.$moment(this.selected_date, "YYYY-MM-DD")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.selected_date_to = this.$moment(this.selected_date, "YYYY-MM-DD")
        .endOf("month")
        .format("YYYY-MM-DD");
    },
  },
  watch: {},
};
</script>

<style lang='scss'>
.box-grey {
  border: solid 1px lightgrey;
}
</style>