export default {
  messages: {
    "en": {
      "ok": "OK",
      "issue1": "project level 1",
      "issue2": "project level 2",
      "issue3": "project level 3",
      "issue4": "project level 4",
      "issue5": "project level 5",
    },
    "de": {
      "ok": "OK",
      "Menu": "Menü",
      "person": "person",
      "Persons": "Personen",
      "energyUnit": "Energieerzeuger",
      "location": "Location",
      "contract": "Vertrag",
      "Dashboard": "Dashboard",
      "device": "Device",
      "admin": "admin",
      "home": "home",
      "administration": "administration",
      "Sign Up": "Registrieren",
      "Forgot password?": "Passwort vergessen?",
      "You don't have an account yet and you want to sign up?": "Sie haben noch keinen User Zugang und wollen sich registrieren?",
      "Back to Login": "Zurück zum Login",
      "invoice": "Rechnung",
      "Reset password": "Passwort zurücksetzen",
      "Logout": "Abmelden",
      "Login": "Anmelden",
      "issue1": "Project Level 1",
      "issue2": "Project Level 2",
    }
  }
}