const emailRule = [
  v => {
    return !!v || "E-Mail is required";
  },
  v =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,32})+$/.test(v) ||
    "E-Mail must be valid"
];

const requiredRule = [v => !!v || "required"];

export { emailRule, requiredRule };
