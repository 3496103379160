import Vue from "vue";
import VueResource from "vue-resource";
import { API_ROOT } from "../config";

Vue.use(VueResource);

// Vue.http.options.crossOrigin = true
// Vue.http.options.credentials = true

/*if (process.env.NODE_ENV === "production") {
  throw new Error("Missing API root");
  // Vue.http.options.root = ...
}*/

Vue.http.interceptors.push((request, next) => {
  request.headers = request.headers || {};
  request.headers.set("Authorization", localStorage.getItem("Authorization"));
  next(response => {
    if (response.status === 401) {
      if (!window.location.pathname === "/login")
        window.location.pathname = "/login";
    }
  });
});

export const AuthResource = Vue.resource(API_ROOT + "auth");
export const SignUpResource = Vue.resource(API_ROOT + "signup");
export const ActivateAccountResource = Vue.resource(
  API_ROOT + "activate/{token}"
);
export const MySelfResource = Vue.resource(API_ROOT + "myself");
export const ForgotPasswordResource = Vue.resource(
  API_ROOT + "forgot_password"
);
export const ResetPasswordResource = Vue.resource(
  API_ROOT + "reset_password{/token}"
);

export const UserResource = Vue.resource(API_ROOT + "user");
export const UserCreateResource = Vue.resource(API_ROOT + "user");
export const UserReadResource = Vue.resource(API_ROOT + "user/{id}");
export const UserUpdateResource = Vue.resource(API_ROOT + "user/{id}");
export const UserDeleteResource = Vue.resource(API_ROOT + "user/{id}");

export const PersonResource = Vue.resource(API_ROOT + "person");
export const PersonCreateResource = Vue.resource(API_ROOT + "person");
export const PersonReadResource = Vue.resource(API_ROOT + "person/{id}");
export const PersonUpdateResource = Vue.resource(API_ROOT + "person/{id}");
export const PersonDeleteResource = Vue.resource(API_ROOT + "person/{id}");

export const EventResource = Vue.resource(API_ROOT + "event");
export const EventReportResource = Vue.resource(API_ROOT + "event/report");
export const EventDeleteResource = Vue.resource(API_ROOT + "event/{id}");
export const EventUpdateResource = Vue.resource(API_ROOT + "event/{id}");
export const EventCreateResource = Vue.resource(API_ROOT + "event");

export const IssueResource = Vue.resource(API_ROOT + "issue");
export const IssueDeleteResource = Vue.resource(API_ROOT + "issue/{id}");
export const IssueUpdateResource = Vue.resource(API_ROOT + "issue/{id}");
export const IssueCreateResource = Vue.resource(API_ROOT + "issue");

export const CostcenterResource = Vue.resource(API_ROOT + "costcenter");
export const CostcenterDeleteResource = Vue.resource(API_ROOT + "costcenter/{id}");
export const CostcenterUpdateResource = Vue.resource(API_ROOT + "costcenter/{id}");
export const CostcenterCreateResource = Vue.resource(API_ROOT + "costcenter");

export const LocationResource = Vue.resource(API_ROOT + "location");
export const LocationCreateResource = Vue.resource(API_ROOT + "location");
export const LocationUpdateResource = Vue.resource(API_ROOT + "location/{id}");
export const LocationDeleteResource = Vue.resource(API_ROOT + "location/{id}");