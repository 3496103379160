<template>
  <draggable  class="dragArea" tag="ul" :list="child" :group="{ name: 'g1' }"  :move="dropTree" @end="endTree" @change='logDrop' 
   >
    <li v-for="el in child" :key="el.name">
      <p class="white lighten-5 font-weight-bold">{{ el.name }} <span class="white--text">{{ el._id }}</span><br>
      <span class="caption">{{ el.type_name }}</span></p>
      <nested-draggable :child="el.child"/>
    </li>
  </draggable>
</template>
<script>
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";

export default {
  props: 
  { child: {
      required: true,
      type: Array
  }/*,
    treeDrop: {
      item: String,
      parent: String
    }*/
  },
  components: {
    draggable
  },
  methods: {
    ...mapActions(
      "location", ["moveLocation"]
      ),
    dropTree(item) {
      this.lastItem = null
      if (item.draggedContext.element) {
        this.lastItem = item.draggedContext.element._id
      }

    },
    endTree(item) {
      let localparent = null
      if (item.to.previousElementSibling) {
        if (item.to.previousElementSibling.firstElementChild) {
          //console.log(item)
          localparent = item.to.previousElementSibling.firstElementChild.innerText
        }
      }
      //this.listLocal = {item: this.lastItem, parent: localparent}
      this.moveLocation({item: this.lastItem, parent: localparent})
    },
    logDrop(item) {
      console.log(item)
    }
  },
  data() {
    return {
      lastItem: String
    }
  },
  computed: {
    ...mapState({
      
    })
    /*,
    listLocal: {
        get() {
          return this.treeDrop
        },
        set(treeDrop) {
          this.$emit('input', treeDrop)
        }
    }*/
    },
  name: "nested-draggable"
};
</script>
<style scoped>
.dragArea {
  min-height: 25px;
  outline: 1px;
  border: 1px;
  border-left-style: 1px;
}
</style>