const REQUEST_PERSONS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_PERSONS = (state, { body }) => {
  state.Persons = body;
  state.status = "success";
};

const FAILURE_REQUEST_PERSONS = state => {
  state.status = "failure";
};

const REQUEST_DELETE_PERSON = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_PERSON = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_PERSON = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_PERSON = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_PERSON = (state, { body }) => {
  state.statusUpdate = "success";
  state.Person = body;
};

const FAILURE_REQUEST_UPDATE_PERSON = state => {
  state.statusUpdate = "failure";
};

const REQUEST_CREATE_PERSON = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_PERSON = (state, { body }) => {
  state.statusCreate = "success";
  state.Person = body;
};

const FAILURE_REQUEST_CREATE_PERSON = (state, { body }) => {
  console.log("ERROR CREATE " + JSON.stringify(body))
  state.statusCreate = "failure";
  state.Person = body;
};

const REQUEST_DEACTIVATE_PERSON = state => {
  state.statusDeactivate = "fetching";
};

const SUCCESS_REQUEST_DEACTIVATE_PERSON = state => {
  state.statusDeactivate = "success";
};

const FAILURE_REQUEST_DEACTIVATE_PERSON = state => {
  state.statusDeactivate = "failure";
};

export default {
  REQUEST_PERSONS,
  SUCCESS_REQUEST_PERSONS,
  FAILURE_REQUEST_PERSONS,
  REQUEST_CREATE_PERSON,
  SUCCESS_REQUEST_CREATE_PERSON,
  FAILURE_REQUEST_CREATE_PERSON,
  REQUEST_DELETE_PERSON,
  SUCCESS_REQUEST_DELETE_PERSON,
  FAILURE_REQUEST_DELETE_PERSON,
  REQUEST_UPDATE_PERSON,
  SUCCESS_REQUEST_UPDATE_PERSON,
  FAILURE_REQUEST_UPDATE_PERSON,
  REQUEST_DEACTIVATE_PERSON,
  SUCCESS_REQUEST_DEACTIVATE_PERSON,
  FAILURE_REQUEST_DEACTIVATE_PERSON
};
