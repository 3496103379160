import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  status: void 0,
  mySelf: void 0,
  statusMySelf: void 0,
  tokenExpiresAt: void 0,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
