export default {
  install: function(Vue, opts = {}) {
    /*
    const _this = this

    const self = new Vue(_this)

    Object.defineProperty(Vue.prototype, '$auth', {
      get () {
        return self
      }
    })*/

    const { router } = opts;

    if (!router) {
      console.info(
        "To use with vue-router, pass it to me Vue.use(VueAuth, {router})"
      );
    } else {
      router.beforeEach(async (to, from, next) => {
        // console.log("beforeEach", { to, from, next });

        try {
          const token = localStorage.getItem("Authorization");

          if (!token) {
            if (
              to.name == "login" ||
              to.name == "resetPassword" ||
              to.name == "resetPasswordConfirm" ||
              to.name == "signUp" ||
              to.name == "accountActivation"
            ) {
              next();
            } else {
              next({ name: "login" });
            }
          } else {
            const store = opts.store;

            //if (!store.state.auth.mySelf) {
            await store.dispatch("auth/getMySelf");

            if (!store.state.auth.mySelf) {
              localStorage.removeItem("Authorization");
              next({ name: "login" });
              //throw new Error("Incorrect token");
            }
            //}

            if (
              to.name == "login" ||
              to.name == "resetPassword" ||
              to.name == "resetPasswordConfirm" ||
              to.name == "signUp"
            ) {
              next({ name: "home" });
            } else {
              next();
            }
          }
        } catch (e) {
          console.log("e", e);

          if (
            to.name == "login" ||
            to.name == "resetPassword" ||
            to.name == "resetPasswordConfirm" ||
            to.name == "signUp" ||
            to.name == "accountActivation"
          ) {
            next();
          } else {
            next({ name: "login" });
          }
        }
      });
    }
  }
};
