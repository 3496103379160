<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex style="max-width: 500px">
        <v-card v-if="status === 'success'" flat>
          <v-alert type="info" :value="status === 'success'"
            >Account created</v-alert
          >
          <v-card-text class="text-xs-center"
            >Thank you for signing up. You will receive a verification
            e-mail.</v-card-text
          >
        </v-card>
        <v-card v-else flat>
          <v-card-text>
            <v-card>
              <v-card-title class="title">{{ $t("Sign Up") }}</v-card-title>
              <v-card-text>
                <v-alert type="error" :value="status === 'failure'"
                  >Something went wrong</v-alert
                >
                <v-alert type="info" :value="status === 'success'"
                  >Account created</v-alert
                >
              </v-card-text>
              <v-card-text>
                <v-form v-model="valid" style="width: 100%; height: 100%">
                  <v-text-field
                    :rules="requiredRule"
                    required
                    v-model="displayname"
                    label="Angezeigter Kurzname"
                  ></v-text-field>
                  <v-text-field
                    :rules="requiredRule"
                    required
                    v-model="fullname"
                    label="Vor- und Nachname"
                  ></v-text-field>
                  <v-text-field
                    :rules="emailRule"
                    required
                    v-model="email"
                    label="E-Mail"
                  ></v-text-field>
                  <v-text-field
                    type="password"
                    :rules="requiredRule"
                    required
                    v-model="password"
                    label="Passwort"
                  ></v-text-field>
                  <v-checkbox
                    v-model="flag_client"
                    label="Join existent client?"
                  ></v-checkbox>
                  <v-text-field
                    v-if="flag_client"
                    :rules="requiredRule"
                    v-model="client_code"
                    label="Please enter client code"
                  ></v-text-field>
                  <v-checkbox
                    v-model="dsgvo"
                    :rules="requiredRule"
                    required
                  >
                    <template v-slot:label>
                      <div>
                        Ich stimme bis auf Widerruf der
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="/dsgvo"
                              @click.stop
                              v-on="on"
                            >
                              Datenschutzerklärung
                            </a>
                          </template>
                          Datenschutzerklärung in einem neuen Fenster öffnen
                        </v-tooltip>
                        zu.
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox
                    v-model="agbs"
                    :rules="requiredRule"
                    required
                  >
                    <template v-slot:label>
                      <div>
                        Ich stimme den
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="https://www.schwarzl-it.com/agbs"
                              @click.stop
                              v-on="on"
                            >
                              AGBs der Thomas Schwarzl IT
                            </a>
                          </template>
                          AGBs in einem neuen Fenster öffnen
                        </v-tooltip>
                        zu.
                      </div>
                    </template>
                  </v-checkbox>
                </v-form>
                <v-divider></v-divider>

                <vue-recaptcha
                  v-if="!isCordova"
                  ref="recaptcha"
                  @verify="onVerify"
                  @expired="onExpired"
                  :sitekey="recaptchaWebSite"
                  :loadRecaptchaScript="true"
                >
                </vue-recaptcha>
                <v-btn
                  v-if="!isCordova"
                  class="ma-1"
                  color="primary"
                  rounded
                  outlined
                  small
                  @click="resetRecaptcha"
                >
                  Reset ReCAPTCHA
                </v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  rounded
                  :disabled="!valid || (!verifiedReCaptcha && !isCordova)"
                  color="primary"
                  @click="
                    signUp({
                      email,
                      password,
                      displayname,
                      fullname,
                      client_code,
                      dsgvo,
                      agbs
                    })
                  "
                  >{{ $t("Sign Up") }}</v-btn
                >
              </v-card-actions>
              <v-divider></v-divider>
              <p align="center">
                <br />
                <router-link :to="{ name: 'login' }">{{
                  $t("Back to Login")
                }}</router-link>
                <br />
                <br />
              </p>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { requiredRule, emailRule } from "../utils/rules";
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "SignUp",
  components: { VueRecaptcha },
  data() {
    return {
      isCordova: process.env.CORDOVA_PLATFORM,
      firebase_id: "",
      verifiedReCaptcha: false,
      displayname: "",
      fullname: "",
      password: "",
      email: "",
      valid: false,
      requiredRule,
      emailRule,
      client_code: "",
      flag_client: false,
      dsgvo: false,
      agbs: false,
      recaptchaWebSite: ""
    };
  },
  created() {
    if (process.env.NODE_ENV === "production") {
      this.recaptchaWebSite = "6LdAPb4hAAAAANzzkvv6e8mCQGutJvLDf-0p4qp5";
    } else {
      this.recaptchaWebSite = "6LfTOdcZAAAAAGGGhb4xj13gN6SAibUjxz8_7m1x";
    }
  },
  methods: {
    ...mapActions("user", ["signUp"]),
    onVerify: function (response) {
      //console.log("Verify: " + response);
      this.verifiedReCaptcha = true;
    },
    onExpired: function () {
      //console.log("Expired");
      this.verifiedReCaptcha = false;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
      this.verifiedReCaptcha = false;
    },
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
    }),
  },
};
</script>

<style scoped>
</style>
