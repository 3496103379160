<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer
        right
        color="black"
        v-model="drawer"
        app
        clipped
        overlay-color="grey darken-4"
        overlay-opacity="50%"
        :style="`margin-top: ${$vuetify.application.top}px`"
        temporary
        width="200"
        :touchless="true"
      >
        <!--&& mySelf.roles.includes('admin')"-->
        <v-list class="pt-3" dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            v-on:click="
              drawer = false;
              pushRoute(item);
            "
            class="pt-2"
          >
            <v-list-item-action>
              <v-icon medium :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize text-primary">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pt-2" v-if="mySelf" v-on:click="doLogout()">
            <v-list-item-action>
              <v-icon medium color="primary">logout</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize text-primary">{{
                $t("Logout")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="pt-2"
            v-if="!mySelf"
            v-on:click="$router.push('login')"
          >
            <v-list-item-action>
              <v-icon medium color="primary">mdi-account</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize text-primary">{{
                $t("Login")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-card v-if="mySelf" flat>
        <v-row row pb-2 pt-3>
          <v-col xs12 pl-1 ml-1 pr-1 mr-1>
            <v-card class="card--flex-toolbar" flat>
              <v-app-bar
                dense
                clipped-left
                fixed
                app
                color="grey darken-4"
                flat
              >
                <v-img
                  position="right center"
                  class="mr-2"
                  max-width="32px"
                  contain
                  src="/img/icons/time-e-64.png"
                ></v-img>
                <!--<v-toolbar-title
                  class="primary--text  h4 text-center "
                >Time-e</v-toolbar-title>      
                <font class="primary--text" face="Georgia" size="5px" color="#FF7A59">Time-e</font>  
                <font class="primary--text" face="Tahoma" size="5px" color="#FF7A59">Time-e</font>  -->
                <font
                  class="primary--text"
                  face="Lucida Grande"
                  size="4px"
                  color="#FF7A59"
                  >Time-e</font
                >
                <v-spacer></v-spacer>
                {{ version }}
                <v-spacer></v-spacer>
                <v-btn large icon v-on:click="drawer = !drawer">
                  <v-icon color="grey lighten-1">menu</v-icon>
                </v-btn>
              </v-app-bar>

              <v-main>
                <router-view></router-view>
              </v-main>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-card v-if="!mySelf" flat>
        <v-row row pb-2 pt-3>
          <v-col xs12 pl-1 ml-1 pr-1 mr-1>
            <v-card class="card--flex-toolbar" flat>
              <v-app-bar dense flat color="grey darken-4">
                <v-img
                  position="right center"
                  class="mr-2"
                  max-width="32px"
                  contain
                  src="/img/icons/time-e-64.png"
                ></v-img>
                <!--<v-toolbar-title class="primary--text  h4">Time-e</v-toolbar-title>-->
                <font
                  class="primary--text"
                  face="Lucida Grande"
                  size="4px"
                  color="#FF7A59"
                  >Time-e</font
                >
                <v-spacer></v-spacer>
                {{ version }}
                <v-spacer></v-spacer>
                <p class="caption white--text" align="right">
                  Schwarzl IT 2022
                </p>
              </v-app-bar>
              <v-progress-linear
                color="blue"
                :active="loading"
                :height="5"
                class="ma-0"
                indeterminate
              ></v-progress-linear>
              <router-view></router-view>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import packageJson from "../package.json";

export default {
  name: "App",
  data() {
    return {
      version: "V" + packageJson.version, //"V1.7.33",
      //version: "V2.5.7",
      drawer: false,
      items: [],
      //mini: true,
      right: null,
      theme1: undefined,
    };
  },
  mounted() {
    this.theme1 = JSON.stringify(this.$vuetify.theme.themes);
  },

  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
    }),
  },
  watch: {
    mySelf(value) {
      //console.log("Check permission");
      if (!value) {
        //console.log("No mySelf!");
        this.IntervalOff();
        this.$router.push({ name: "login" });
      }   
      this.items = [];
      try {
        console.log(this.mySelf.roles);
        // if (this.mySelf.roles.includes("user") ) {
        //    this.items.push({
        //         title: "Home",
        //         icon: "mdi-home",
        //         color: "blue darken-2",
        //         path: "/home",
        //       });
        //   this.items.push({
        //         title: "Projects",
        //         icon: "mdi-archive",
        //         color: "green",
        //         path: "/issue",
        //       });
        //    this.items.push({
        //         title: "Report",
        //         icon: "mdi-chart-box-outline",
        //         color: "orange",
        //         path: "/report",
        //       });

        // } else if (this.mySelf.roles.includes("admin") || this.mySelf.roles.includes("clientAdmin")) {

        //console.log("admin detected")
        this.$router.options.routes.forEach((route) => {
          if (!_.isUndefined(route.icon)) {
            if (
              this.mySelf.roles.includes("clientAdmin") &&
              route.path == "/loc"
            ) {
              //skip
            } else if (
              this.mySelf.roles.includes("user") &&
              (route.path == "/loc" ||
                route.path == "/costcenter" ||
                route.path == "/user")
            ) {
              //skip
            } else {
              this.items.push({
                title: route.name,
                icon: route.icon,
                color: route.color,
                path: route.path,
              });
            }
          }
        });
        //}
        /*if (this.mySelf.roles.includes("clientAdmin")) {
          let route = this.$router.options.routes.find(obj => {
            return obj.name == "Costcenter";
          })
          
           this.items.push({
                title: route.name,
                icon: route.icon,
                color: route.color,
                path: route.path,
              });
       }*/
      } catch {}
    },
  },
  destroyed() {
    this.IntervalOff();
  },
  methods: {
    ...mapActions("auth", ["getMySelf", "logout"]),

    pushRoute(item) {
      let item_path = item.path;
      item_path = item_path.slice(1, item.path.length);
      //this.mini = true
      //this.drawer = false
      this.$router.push(item_path);
    },
    actualRoute(path) {
      let getRoute = _.find(this.items, { path });
      if (_.isUndefined(getRoute)) {
        getRoute = {
          color: "",
          icon: "",
          name: "",
        };
      }
      return getRoute;
    },
    doLogout() {
      try {
        let authData = {};
        authData = JSON.parse(localStorage.getItem("authData"));
        authData.autoLogin = false;
        localStorage.setItem("authData", JSON.stringify(authData));
      } catch (err) {
        console.log(err);
      }
      this.IntervalOff();
      this.logout();
    },
    IntervalOff() {
      try {
        if (!_.isUndefined(this.$store.authInterval)) {
          clearInterval(this.$store.authInterval);
          this.$store.authInterval = undefined;
        }
      } catch (e) {
        //
      }
    },
  },
};
</script>

<style lang='scss'>
.card--flex-toolbar {
  margin-top: 0px;
  margin-bottom: 0px;
}

.body {
  overscroll-behavior-y: contain !important;
  overflow-y: hidden !important; // override Vuetify's default style
}

.html {
  overscroll-behavior-y: contain !important;
  overflow-y: hidden !important; // override Vuetify's default style
}
body {
  overscroll-behavior-y: contain !important;
  overflow-y: hidden !important; // override Vuetify's default style
}
html {
  overscroll-behavior-y: contain !important;
  overflow-y: hidden !important; // override Vuetify's default style
}

#app {
  overflow-x: hidden;
  //overflow-y: hidden;

  background-color: #000000;

  //position:absolute;
  width: 100%;
  height: 100%;
}
</style>

