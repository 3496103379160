var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height pa-0"},[_c('v-row',[_c('v-col',{staticClass:"caption"},[_vm._v(" Chat Protocol of "+_vm._s(_vm.issue.name)+" ")])],1),_c('v-row',{staticClass:"no-gutters pr-3"},[_c('v-col',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"cols":"auto"}},[_c('v-responsive',{staticClass:"overflow-y-auto fill-height",attrs:{"height":_vm.chat_height}},[_c('v-card',{staticClass:"d-flex flex-column fill-height",attrs:{"flat":""}},[_c('div',{staticClass:"flex-grow-1",attrs:{"id":"chat_box"}},[_c('v-card-text',{staticClass:"pa-0 pr-2"},[_vm._l((_vm.new_chat_data),function(msg){return _c('div',{key:msg._id,class:msg.user == _vm.user_id
                      ? 'd-flex flex-row-reverse'
                      : msg.issue == '0'
                      ? 'd-flex flex-row justify-center'
                      : 'd-flex flex-row'},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',{staticClass:"pa-0 px-3 ma-0 my-2 caption",style:(msg.user == _vm.user_id
                            ? 'background-color: #00AA00FF;  border-radius: 15px; height: auto; white-space: normal; max-width: 90%;'
                            : msg.issue == '0'
                            ? 'height: auto; white-space: normal;'
                            : 'background-color: #0050FFFF;  border-radius: 15px; height: auto; white-space: normal; max-width: 90%;'),attrs:{"align":msg.user == _vm.user_id ? 'end' : 'start'}},[(msg.user != _vm.user_id && msg.issue != '0')?_c('div',[_vm._v(" "+_vm._s(msg.name)),_c('br')]):(msg.issue == '0')?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(msg.createdAt,"DD.MM.YY"))+" ")]):_vm._e(),(msg.issue != '0')?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var hover = ref.hover;
return [_c('div',_vm._g({staticClass:"white--text",attrs:{"dark":""}},on),[_vm._v(" "+_vm._s(msg.msg)+" "),_c('sub',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm._f("moment")(msg.createdAt,"HH:mm"))+" ")]),(hover)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" expand_more ")]):_vm._e()],1)]}}],null,true)}):_vm._e()],1)]}}],null,true)},[(msg.user != _vm.user_id)?_c('v-list',{staticClass:"pa-0 ma-0",staticStyle:{"background-color":"#50505080 !important"}},[_c('v-list-item',[_c('v-icon',{staticClass:"pl-3",attrs:{"small":""}},[_vm._v(" mdi-thumb-up ")]),_c('v-icon',{staticClass:"pl-3",attrs:{"small":""}},[_vm._v(" mdi-cancel ")]),_c('v-icon',{staticClass:"pl-3",attrs:{"small":""}},[_vm._v(" mdi-thumb-down ")])],1)],1):_c('v-list',{staticClass:"pa-0 ma-0",staticStyle:{"background-color":"#50505080 !important"}},[_c('v-list-item',[_c('v-icon',{staticClass:"pl-3",attrs:{"small":""}},[_vm._v(" mdi-arrow-right-bold-box ")]),(_vm.checkMessageCreatedAt(msg))?_c('v-icon',{staticClass:"pl-3",attrs:{"small":""},on:{"click":function($event){return _vm.deleteChatMessage(msg)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)],1)],1)],1)})],2)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }