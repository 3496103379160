import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  User: void 0,
  Users: void 0,
  ReadUser: void 0,
  status: void 0,
  statusUpdate: void 0,
  statusDelete: void 0,
  statusCreate: void 0,
  statusAccountActivation: void 0,
  statusForgotPassword: void 0,
  statusResetPassword: void 0,
  statusUser: void 0,
  statusUsers: void 0,
  selectedDate: void 0,
  statusReadUser: void 0
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
