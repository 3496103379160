export default {
  REQUEST_ACTIVATE_ACCOUNT: "REQUEST_ACTIVATE_ACCOUNT",
  SUCCESS_REQUEST_ACTIVATE_ACCOUNT: "SUCCESS_REQUEST_ACTIVATE_ACCOUNT",
  FAILURE_REQUEST_ACTIVATE_ACCOUNT: "FAILURE_REQUEST_ACTIVATE_ACCOUNT",

  REQUEST_FORGOT_PASSWORD: "REQUEST_FORGOT_PASSWORD",
  SUCCESS_REQUEST_FORGOT_PASSWORD: "SUCCESS_REQUEST_FORGOT_PASSWORD",
  FAILURE_REQUEST_FORGOT_PASSWORD: "FAILURE_REQUEST_FORGOT_PASSWORD",

  REQUEST_RESET_PASSWORD: "REQUEST_RESET_PASSWORD",
  SUCCESS_REQUEST_RESET_PASSWORD: "SUCCESS_REQUEST_RESET_PASSWORD",
  FAILURE_REQUEST_RESET_PASSWORD: "FAILURE_REQUEST_RESET_PASSWORD"
};
