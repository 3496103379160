import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import user from "./modules/user";
import person from "./modules/person";
import event from "./modules/event";
import issue from "./modules/issue";
import costcenter from "./modules/costcenter";
import location from "./modules/location";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    drawer: true,
    selectedLocation: undefined,
    authInterval: undefined
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_DRAWER(state, _drawer) {
      state.drawer = _drawer;
    },
    TO_LOGIN(state, value) {
      localStorage.removeItem("Authorization");
      state.auth.mySelf = null;
    },
  },
  modules: {
    auth,
    user,
    person,
    event,
    issue,
    costcenter,
    location
  }
});
