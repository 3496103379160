import api from "../../../api";

export default {
  async getLocation({ commit }) {
    commit("REQUEST_LOCATIONS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_LOCATIONS",
        await api.getLocation()
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_LOCATIONS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteLocation({ commit }, id) {
    commit("REQUEST_DELETE_LOCATION");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_LOCATION",
        await api.deleteLocation(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DELETE_LOCATION");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateLocation({ commit }, item) {
    commit("REQUEST_UPDATE_LOCATION");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_LOCATION",
        await api.updateLocation(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_LOCATION");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createLocation({ commit }, item) {
    commit("REQUEST_CREATE_LOCATION");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_LOCATION",
        await api.createLocation(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_LOCATION");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async moveLocation({ commit }, item) {
    commit("REQUEST_MOVE_LOCATION");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_MOVE_LOCATION", item);
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_MOVE_LOCATION");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deactivateLocation({ commit }, id) {
    commit("REQUEST_DEACTIVATE_LOCATION");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DEACTIVATE_LOCATION", 
        await api.deactivateLocation(id)
        );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DEACTIVATE_LOCATION");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
