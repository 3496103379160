import {
  AuthResource,
  SignUpResource,
  ActivateAccountResource,
  MySelfResource,
  ForgotPasswordResource,
  ResetPasswordResource,
  UserResource,
  UserCreateResource,
  UserReadResource,
  UserUpdateResource,
  UserDeleteResource,
  PersonResource,
  PersonCreateResource,
  PersonReadResource,
  PersonUpdateResource,
  PersonDeleteResource,
  EventCreateResource,
  EventResource,
  EventUpdateResource,
  EventDeleteResource,
  EventReportResource,
  IssueCreateResource,
  IssueResource,
  IssueUpdateResource,
  IssueDeleteResource,
  CostcenterCreateResource,
  CostcenterResource,
  CostcenterUpdateResource,
  CostcenterDeleteResource,
  LocationResource,
  LocationCreateResource,
  LocationUpdateResource,
  LocationDeleteResource,
} from "./resources";

export default {
  authenticate({ email, password }) {
    return AuthResource.save({}, { email, password });
  },
  mySelf() {
    return MySelfResource.get();
  },
  signUp(obj) {
    return SignUpResource.save({}, obj);
  },
  activateAccount({ token }) {
    return ActivateAccountResource.get({ token }, {});
  },
  forgotPassword({ email }) {
    return ForgotPasswordResource.save({ email });
  },
  resetPassword({ token }) {
    return ResetPasswordResource.save({ token }, {});
  },
  getUsers() {
    return UserResource.get();
  },
  createUser(user) {
    return UserCreateResource.save({}, user);
  },
  readUser(obj) {
    return UserReadResource.get(obj, {});
  },
  deleteUser(id) {
    return UserDeleteResource.delete({ id }, {});
  },
  updateUser(user) {
    return UserUpdateResource.update({ id: user._id }, user);
  },
  getPersons() {
    return PersonResource.get();
  },
  createPerson(user) {
    return PersonCreateResource.save({}, user);
  },
  readPerson({ id }) {
    return PersonReadResource.get({ id }, {});
  },
  deletePerson(id) {
    return PersonDeleteResource.delete({ id }, {});
  },
  updatePerson(user) {
    return PersonUpdateResource.update({ id: user._id }, user);
  },
  getEvents(filter) {
    return EventResource.get(filter);
  },
  getEventsReport(filter) {
    return EventReportResource.get(filter);
  },
  deleteEvent(id) {
    return EventDeleteResource.delete({ id }, {});
  },
  updateEvent(event) {
    return EventUpdateResource.update({ id: event._id }, event);
  },
  createEvent(event) {
    return EventCreateResource.save({}, event);
  },
  getIssues(filter) {
    return IssueResource.get(filter);
  },
  deleteIssue(id) {
    return IssueDeleteResource.delete({ id }, {});
  },
  updateIssue(event) {
    return IssueUpdateResource.update({ id: event._id }, event);
  },
  createIssue(event) {
    return IssueCreateResource.save({}, event);
  },
  getCostcenters(filter) {
    return CostcenterResource.get(filter);
  },
  deleteCostcenter(id) {
    return CostcenterDeleteResource.delete({ id }, {});
  },
  updateCostcenter(event) {
    return CostcenterUpdateResource.update({ id: event._id }, event);
  },
  createCostcenter(event) {
    return CostcenterCreateResource.save({}, event);
  },
  getLocation() {
    return LocationResource.get();
  },
  createLocation(location) {
    return LocationCreateResource.save({}, location);
  },
  updateLocation(location) {
    return LocationUpdateResource.update({ id: location._id }, location);
  },
  deleteLocation(id) {
    return LocationDeleteResource.delete({ id }, {});
  },
};
