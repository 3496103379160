const REQUEST_COSTCENTERS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_COSTCENTERS = (state, { body }) => {
  state.Costcenters = body;
  state.status = "success";
};

const FAILURE_REQUEST_COSTCENTERS = state => {
  state.status = "failure";
};

const REQUEST_DELETE_COSTCENTER = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_COSTCENTER = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_COSTCENTER = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_COSTCENTER = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_COSTCENTER = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_COSTCENTER = state => {
  state.statusUpdate = "failure";
};


const REQUEST_CREATE_COSTCENTER = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_COSTCENTER = (state, { body }) => {
  state.CreatedCostcenter = body
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_COSTCENTER = state => {
  
  state.statusCreate = "failure";
};


export default {
  REQUEST_COSTCENTERS,
  SUCCESS_REQUEST_COSTCENTERS,
  FAILURE_REQUEST_COSTCENTERS,
  REQUEST_CREATE_COSTCENTER,
  SUCCESS_REQUEST_CREATE_COSTCENTER,
  FAILURE_REQUEST_CREATE_COSTCENTER,
  REQUEST_DELETE_COSTCENTER,
  SUCCESS_REQUEST_DELETE_COSTCENTER,
  FAILURE_REQUEST_DELETE_COSTCENTER,
  REQUEST_UPDATE_COSTCENTER,
  SUCCESS_REQUEST_UPDATE_COSTCENTER,
  FAILURE_REQUEST_UPDATE_COSTCENTER,

};
