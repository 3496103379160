import Vue from "vue";
import Router from "vue-router";
import LoginComponent from "./views/Login.vue";
import SignUpComponent from "./views/SignUp.vue";
import AccountActivation from "./views/AccountActivation.vue";
import ResetPasswordComponent from "./views/ResetPassword.vue";
import Home from "./views/Home.vue";
import User from "./views/User.vue";
import Issue from "./views/Issue.vue";
import Costcenter from "./views/Costcenter.vue";
import Location from "./views/Location.vue";
import Dsgvo from "./views/Dsgvo.vue";

import Report from "./views/Report.vue";

Vue.use(Router);

export default new Router({
  mode: "hash",
  routes: [
    {
      path: "/",
      redirect: {
        name: "login",
      },
    },
    {
      path: "/login",
      name: "login",
      component: LoginComponent,
    },
    {
      path: "/sign-up",
      name: "signUp",
      component: SignUpComponent,
    },
    {
      path: "/activate-account/:token?",
      component: AccountActivation,
      name: "accountActivation",
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: ResetPasswordComponent,
    },
    {
      path: "/confirm-password-reset/:token?",
      name: "resetPasswordConfirm",
      component: ResetPasswordComponent,
    },
    {
      path: "/home",
      icon: "mdi-home",
      color: "blue darken-2",
      name: "home",
      component: Home,
    },
    {
      path: "/user",
      name: "User",
      icon: "account_box",
      color: "deep-orange",
      component: User,
    },
    {
      path: "/issue",
      name: "Projects",
      icon: "mdi-archive",
      color: "green",
      component: Issue,
    },
    {
      path: "/costcenter",
      name: "Costcenter",
      icon: "mdi-cash-check",
      color: "cyan",
      component: Costcenter,
    },
    {
      path: "/loc",
      name: "location",
      icon: "business",
      color: "blue darken-2",
      component: Location,
    },

    {
      path: "/report",
      name: "report",
      icon: "mdi-chart-box-outline",
      color: "orange darken-2",
      component: Report,
    },
    {
      path: "/dsgvo",
      name: "Dsgvo",
      component: Dsgvo,
    },
  ],
});
